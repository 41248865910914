import { gql } from '@apollo/client';
import ProjectList from '../Projects/ProjectList';
import useProjectList, { PROJECT_COLLECTOR_FRAGMENT } from '../Projects/useProjectList';
import PageStyle from '../UI/PageStyle';
import { useTeamContext } from './TeamContext';

const TeamProjects = () => {
  const { team } = useTeamContext();

  const projectListProps = useProjectList(TEAM_PROJECTS_QUERY, { id: team.id }, data => data.team);

  return (
    <PageStyle design="simple">
      <ProjectList mode={'auto'} {...projectListProps} />
    </PageStyle>
  );
};

export const TEAM_PROJECTS_QUERY = gql`
  query TeamProjects($id: ID!, $healthLevel: [HealthLevel!], $statuses: [ProjectStatus!]) {
    team(id: $id) {
      id
      ...ProjectCollector
    }
  }
  ${PROJECT_COLLECTOR_FRAGMENT}
`;

export default TeamProjects;
