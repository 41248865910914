import { Box, Stack } from '@mui/material';

import { ReactComponent as HeaderLogoSvg } from '../Icons/Hyve-Logo.svg';
import useUser from '../User/useUser';

type HeaderLogoProps = {
  width?: number;
  beta?: boolean;
};

const NON_BETA_EMAILS = ['anthony+app-store@hyve.app'];

const HeaderLogo = ({ width, beta = true }: HeaderLogoProps) => {
  const { user } = useUser();
  const email = user?.email || '';
  const isApproverEmail = NON_BETA_EMAILS.includes(email);
  const showBeta = beta && !isApproverEmail;

  return (
    <Stack direction='row' width={width} pl={2.5} alignItems='center' spacing='12px'>
      <HeaderLogoSvg />
      {showBeta && (
        <Box color='#666' border='1px solid #666' padding='0px 6px' borderRadius='10px' fontSize='10px'>
          BETA
        </Box>
      )}
    </Stack>
  );
};

export default HeaderLogo;
