import { useEffect } from 'react';
import { useMatches } from 'react-router';

interface RouteHandle {
  title?: string;
}

const usePageTitle = (dynamicTitle?: string) => {
  const matches = useMatches();

  useEffect(() => {
    let title: string;

    // If dynamicTitle is provided, use it
    if (dynamicTitle) {
      title = dynamicTitle;
    } else {
      // Otherwise, find the last route match with a title property
      const currentRoute = matches
        .slice()
        .reverse()
        .find(match => (match.handle as RouteHandle)?.title);

      title = (currentRoute && (currentRoute.handle as RouteHandle).title) || 'Default Title';
    }

    document.title = `Hyve - ${title}`;
  }, [matches, dynamicTitle]); // Depend on matches and dynamicTitle
};

const PageTitle = () => {
  usePageTitle();
  return null;
}

export default PageTitle;