import React from 'react';
import PageStyle from '../UI/PageStyle';
import { Outlet } from 'react-router';
import { useClientContext } from './ClientContext';
import { useCategory } from '../Category/useCategory';
import { useCategoryDashboardTabs } from '../Category/CategoryDashboard';
import TertiaryNavigationTabs, { useTertiaryNavigationTabs } from '../Navigation/TertiaryNavigationTabs';
import { useScope } from '../Scope/useScope';

const ClientDashboard = () => {
  const { client, clientPath } = useClientContext();
  const { category } = useCategory('ClientsCategory');
  const { tabs } = useCategoryDashboardTabs(category, clientPath);
  const tertiaryNavigationTabProps = useTertiaryNavigationTabs(tabs, clientPath);
  const { scope } = useScope();
  const scopePrefix = scope.prefix;

  return (
    <PageStyle design='standard' theme='dark' fullWidth backgroundProps={{ pt: 3 }}>
      <TertiaryNavigationTabs {...tertiaryNavigationTabProps} />

      {/*<Outlet context={{ scorable: client, category, scopePrefix, icon, stats, detailsTitle, children, actions }} />*/}
      <Outlet context={{ client, category, scopePrefix }} />
    </PageStyle>
  );
};

export default ClientDashboard;
