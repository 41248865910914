import { Link as RouterLink } from 'react-router';
import { Box, Stack, StackProps, useTheme } from '@mui/material';
import { ReactComponent as HyveLogoCenteredLightSvg } from '../Icons/Hyve-Logo-Centered-Light.svg';
import { ReactComponent as HyveLogoCenteredDarkSvg } from '../Icons/Hyve-Logo-Centered-Dark.svg';

type HyveLogoCenteredProps = StackProps & {
  width?: string;
  clickable?: boolean;
};

const HyveLogoCentered = ({ width = '150px', clickable = true, ...stackProps }: HyveLogoCenteredProps) => {
  const theme = useTheme();

  const logo = (
    <Box width={width}>
      {theme.palette.mode === 'light' ? <HyveLogoCenteredLightSvg width={width} height={width} /> : <HyveLogoCenteredDarkSvg width={width} height={width} />}
    </Box>
  );

  return (
    <Stack justifyContent='center' alignItems='center' {...stackProps}>
      {clickable ? <RouterLink to='/'>{logo}</RouterLink> : logo}
    </Stack>
  );
};

export default HyveLogoCentered;
