import React, { useEffect, useState } from 'react';
import { NavLink, resolvePath, UIMatch, useMatches } from 'react-router';
import { Box, Tab, Tabs } from '@mui/material';
import { useWorkspace } from '../Workspace/useWorkspace';
import { Role } from '../User/Roles';
import { useLayout } from '../UI/LayoutContext';

type ExtendedUIMatch = UIMatch & { handle?: { secondaryArea?: string } };

export const useSecondaryNavigationTabs = (tabs: SecondaryNavigationTab[], basePath: string) => {
  const matches = useMatches() as ExtendedUIMatch[];

  const secondaryArea = matches.find(match => match.handle?.secondaryArea)?.handle?.secondaryArea;
  const matchingTab = tabs.find(tab => tab.key === secondaryArea) ?? tabs[0];
  const [selectedTab, setSelectedTab] = useState<SecondaryNavigationTab>(matchingTab);

  useEffect(() => {
    if (matchingTab.key !== selectedTab.key) {
      setSelectedTab(matchingTab);
    }
  }, [matchingTab, selectedTab]);

  return { tabs, selectedTab, setSelectedTab, basePath };
};

interface SecondaryNavigationTabsProps {
  tabs: SecondaryNavigationTab[];
  selectedTab: SecondaryNavigationTab;
  setSelectedTab: (selectedTab: SecondaryNavigationTab) => void;
  onChange?: (tab: SecondaryNavigationTab) => void;
  basePath: string;
}

export const SecondaryNavigationTabs = ({ tabs, selectedTab, setSelectedTab, basePath }: SecondaryNavigationTabsProps) => {
  const { workspace } = useWorkspace();
  const layout = useLayout();

  // Filter out sections for which the user does not have access
  const filteredTabs = tabs.filter(tab => (tab.roles ? tab.roles.some(role => workspace.effectiveRoles.includes(role)) : true));

  const selectedTabIndex = tabs.findIndex(tab => tab.key === selectedTab.key);

  const handleChange = (_: React.SyntheticEvent, index: number) => {
    const selectedTab = tabs[index];
    setSelectedTab(selectedTab);
  };

  return (
    <Box sx={{ maxWidth: '100%', overflow: 'visible' }}>
      <Tabs
        value={selectedTabIndex}
        onChange={handleChange}
        aria-label='client navigation tabs'
        variant='scrollable'
        scrollButtons='auto'
        allowScrollButtonsMobile={false}
        sx={{
          '.MuiTabs-indicator': {
            height: '4px',
            bottom: '-1px',
            backgroundColor: theme => theme.palette.secondary.main,
          },
        }}
      >
        {filteredTabs.map((tab, index) => (
          <Tab
            key={tab.key}
            value={index}
            label={tab.label}
            to={resolvePath(tab.path, basePath).pathname}
            component={NavLink}
            sx={{
              textTransform: 'inherit',
              minWidth: layout === 'mobile' ? 'auto' : '100px', // Conditional styling based on layout
              '&.Mui-selected': {
                color: '#ffd700',
                opacity: 1.0,
              },
            }}
          />
        ))}
      </Tabs>
    </Box>
  );
};

export interface SecondaryNavigationTab {
  key: string;
  label: string;
  path: string;
  roles?: Role[];
}
