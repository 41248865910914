import React, { createContext, useState, useEffect, useContext } from 'react';
import { jwtDecode } from 'jwt-decode';
import hyveApi from '../util/hyveApi';
import { WORKSPACE_ID_KEY } from '../Workspace/WorkspaceInterface';
import { TOKEN, UserInterface } from './User';

export const useAuthentication = () => {
  const context = useContext(AuthenticationContext);
  if (!context) {
    throw new Error('useAuthentication must be called within an AuthenticationProvider');
  }
  return context;
};

interface AuthenticationContextValue {
  signIn: (email: string, password: string) => Promise<UserInterface>;
  authenticate: () => void;
  deauthenticate: () => void;
  authenticated: boolean;
  loading: boolean;
  token: string | null;
  userId: string | null;
}

const AuthenticationContext = createContext<AuthenticationContextValue | undefined>(undefined);

interface AuthenticationProviderProps {
  children: React.ReactNode;
}

export const AuthenticationProvider = ({ children }: AuthenticationProviderProps) => {
  const [token, setToken] = useState<string | null>(localStorage.getItem(TOKEN));
  const [userId, setUserId] = useState<string | null>(null);
  const [loading, setLoading] = useState(true); // New state

  useEffect(() => {
    if (token) {
      const decodedToken: any = jwtDecode(token);
      setUserId(decodedToken.sub || null);
    } else {
      setUserId(null);
    }
    setLoading(false);
  }, [token]);

  useEffect(() => {
    const deauthenticateForTesting = () => {
      console.log('DEAUTH FOR TESTING');
      deauthenticate();
    };

    window.addEventListener('deauthenticate-for-testing', deauthenticateForTesting);
    return () => {
      window.removeEventListener('deauthenticate-for-testing', deauthenticateForTesting);
    };
  }, []);

  const authenticate = () => {
    const storedToken = localStorage.getItem(TOKEN);
    setToken(storedToken);
  };

  const deauthenticate = async () => {
    try {
      await hyveApi.create().delete('/users/sign_out');
    } catch (error) {
      console.error('Error logging out:', error);
    } finally {
      localStorage.removeItem(TOKEN);
      localStorage.removeItem(WORKSPACE_ID_KEY);
      setToken(null);
      setUserId(null);
    }
  };

  const signIn = async (email: string, password: string): Promise<UserInterface> => {
    const response = await hyveApi.create({ noAuth: true }).post('/users/sign_in', {
      user: { email, password },
    });

    const authorizationHeader = response.headers.authorization!;
    const token = authorizationHeader.split(' ')[1];
    const user = response.data;

    localStorage.setItem(TOKEN, token);
    authenticate();

    return user;
  };

  const value = {
    signIn,
    authenticate,
    deauthenticate,
    authenticated: !!token,
    loading,
    token,
    userId,
  };

  return <AuthenticationContext.Provider value={value}>{children}</AuthenticationContext.Provider>;
};

export default AuthenticationContext;
