import { gql } from '@apollo/client';
import { Category } from '../Category/Category';

import { SCORABLE_SURVEY_FIELDS_FRAGMENT } from '../Survey/useSurvey';
import { ClientCollector } from '../Client/ClientCollector';
import { ScorableBase } from '../Scores/ScorableBase';
import { Role } from '../User/Roles';
import { ScheduleInterface } from '../util/schedule';
import { UserInterface } from '../User/User';
import { MemberStats } from '../Membership/MemberStats';
import { MembershipInterface } from '../Membership/Membership';
import { CompanyInterface } from '../Company/CompanyInterface';

export interface WorkspaceInterface extends ClientCollector, WorkspaceStatsInterface, MembershipInterface, ScorableBase {
  __typename: 'Workspace';
  type: 'Workspace';
  company: CompanyInterface; // TODO add CompanyInterface
  categories: Array<Category>;
  users: UserInterface[];
  teams: Array<any>; // TODO add TeamInterface
  effectiveRoles: Role[];
  hasSlack: boolean;
  slackTeamId: string | null;
  surveySchedule: ScheduleInterface;
  needsMyIntelligence: boolean;
  user: { hasSlackUserAccess: boolean };
  aiEnabled: boolean;
}

export interface WorkspaceStatsInterface {
  surveyStats: {
    count: number;
    recentlyCompletedCount: number;
    allTimeCount?: number;
  }; // TODO add WorkspaceStats Interface
  projectStats: { count: number };
  memberStats: MemberStats;
  teamStats: { count: number; allTeamCount: number };
  intelligenceStats: {
    relativeContribution: number;
    contributorPercentage: number;
  };
}

export interface WorkspaceScoresInterface extends WorkspaceInterface {
  healthDelta: number;
}

export const WORKSPACE_ID_KEY = 'workspaceId';

export const WORKSPACE_FIELDS_FRAGMENT = gql`
  fragment WorkspaceFields on Workspace {
    id
    name
    effectiveRoles # to initialize user roles for the workspace
    member

    hasSlack # to show/hide add to slack notice
    slackTeamId
    slackTeamName # to add to slack
    needsMyIntelligence
    aiEnabled
    ...ScorableSurveyFields

    user {
      id
      hasSlackUserAccess
    }

    surveyStats {
      count # to show/hide the intelligence needed indicator
    }

    projectStats {
      count: count # to show/hide contribute and collect intelligence for the projects module
    }

    teamStats {
      allTeamCount: count(scope: { type: Unscoped })
    }

    company {
      id
      name
      health
      intelligence
      imageUrl
    }

    categories {
      id
      name
      type
      modules {
        id
        name
        type
        active
      }
    }

    teams(scope: { type: Unscoped }) {
      # to create team scopes. returns all teams that the user has access to, not only ones that she manages
      id
      name
    }
  }
  ${SCORABLE_SURVEY_FIELDS_FRAGMENT}
`;
