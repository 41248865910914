import React, { useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import { z } from 'zod';
import { Controller, useController, useFormContext } from 'react-hook-form';

import { Autocomplete, AutocompleteRenderInputParams, Avatar, ListItem, ListItemIcon, ListItemText, Stack, TextField } from '@mui/material';
import { useWorkspace } from '../Workspace/useWorkspace';
import CompanyAvatar from '../Company/CompanyAvatar';
import { verticalSchema, subVerticalSchema } from '../Company/VerticalSelect';
import { userSchema } from '../User/User';
import { useClearbitCompanies } from '../Company/useClearbitCompanies';
import { useFieldSet } from '../UI/FieldSet';
import { CompanyOption } from '../UI/CompanyField';
import { ImportanceRatingField } from '../Scores/ImportanceRating';
import VerticalSelect from '../Company/VerticalSelect';

export const clientSchema = z.object({
  id: z.string(),
  name: z.string().min(1, 'Name is required'),
  imageUrl: z
    .string()
    .url()
    .optional()
    .nullable()
    .default('')
    .or(z.literal(''))
    .transform(value => (value === null ? '' : value)),
  domain: z.string().nullable().optional().default(''),
  importance: z.number().int().nullable().optional(),
  vertical: verticalSchema.optional().nullable(),
  subVertical: subVerticalSchema.optional().nullable(),
  users: z.array(userSchema).optional(),
});

export const ClientFields = () => {
  const { workspace } = useWorkspace();

  const { data, loading } = useQuery(WORKSPACE_QUERY, { variables: { id: workspace.id } });

  // const { withNamespace, setValueWithNamespace } = useFieldSet();

  if (!data && loading) {
    //    return null;
  }

  return (
    <Stack spacing={2}>
      <ClientNameField />
      <ClientImageUrlField />
      <ClientDomainField />
      <ClientImportanceField />
      <VerticalSelect />
    </Stack>
  );
};

const ClientNameField = ({ autoFocus = true }: { autoFocus?: boolean }) => {
  const { control } = useFormContext();
  const { withNamespace, setValueWithNamespace } = useFieldSet();
  const name = withNamespace('name');

  const [, setClientName] = useState('');

  const { setCompanyQuery, companies } = useClearbitCompanies();

  const handleSelectCompany = (_: any, value: CompanyOption | string | null) => {
    if (typeof value === 'string') {
      // Handle the scenario where value is just a string
      setValueWithNamespace('name', value);
    } else if (value) {
      // Handle the scenario where value is a CompanyOption object
      setValueWithNamespace('name', value.name);
      setValueWithNamespace('imageUrl', value.imageUrl);
      setValueWithNamespace('domain', value.domain);
    } else {
      // Handle the scenario where value is null (no selection)
      setValueWithNamespace('name', '');
      setValueWithNamespace('imageUrl', '');
      setValueWithNamespace('domain', '');
    }
  };

  const handleNameChange = async (_: any, value: string) => {
    setClientName(value);
    setValueWithNamespace('name', value);
    setCompanyQuery(value);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          options={companies}
          value={field.value || null}
          // getOptionSelected = {(option, value) => option.name === value}
          getOptionLabel={option => {
            return typeof option === 'string' ? option : option.name;
          }}
          freeSolo
          autoHighlight
          openOnFocus={false}
          renderOption={(props, option) => {
            // console.log(props)
            // console.log(option)
            const company: CompanyOption = { name: option.name, imageUrl: option.imageUrl };
            return (
              <ListItem {...props} key={props.id} className={`client-name-option ${props.className}`}>
                <ListItemIcon>
                  <CompanyAvatar company={company} />
                </ListItemIcon>
                <ListItemText primary={option.name} secondary={option.domain} />
              </ListItem>
            );
          }}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <TextField
              {...params}
              data-cy='name'
              autoFocus={autoFocus}
              variant='outlined'
              fullWidth
              label='Name (Required)'
              error={!!error}
              helperText={error?.message}
            />
          )}
          onInputChange={handleNameChange}
          onChange={handleSelectCompany}
        />
      )}
    />
  );
};

const ClientImageUrlField = () => {
  const { control } = useFormContext();
  const { withNamespace } = useFieldSet();
  const name = withNamespace('imageUrl');
  // console.log(name);
  const { field } = useController({ name, control });
  // console.log(field);
  const { value } = field;
  return (
    <Stack direction='row' alignItems='center' spacing={1}>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            value={value || ''}
            data-cy='imageUrl'
            name='imageUrl'
            variant='outlined'
            fullWidth
            label='Logo Image URL'
            autoComplete='photo'
            error={!!error}
            helperText={error?.message}
          />
        )}
      />
      {value && <Avatar src={value} />}
    </Stack>
  );
};

const ClientDomainField = () => {
  const { control } = useFormContext();
  const { withNamespace } = useFieldSet();
  const name = withNamespace('domain');
  const { field } = useController({ name, control });
  //  console.log(field);
  const { value } = field;
  return (
    <Controller
      name='domain'
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          value={value || ''}
          variant='outlined'
          fullWidth
          label='Website'
          autoComplete='photo'
          error={!!error}
          helperText={error?.message}
        />
      )}
    />
  );
};

const ClientImportanceField = () => {
  const { withNamespace } = useFieldSet();
  const name = withNamespace('importance');

  return <ImportanceRatingField name={name} />;
};

const WORKSPACE_QUERY = gql`
  query Workspace($id: ID!) {
    workspace(id: $id) {
      id
      clients {
        id
        name
      }
    }
  }
`;

export default ClientFields;
