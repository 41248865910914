import React, { Fragment, useEffect, useState } from 'react';
import hyveApi from '../util/hyveApi';
import { DataGrid } from '@mui/x-data-grid';
import { useParams } from 'react-router';
import { GridToolbar } from '@mui/x-data-grid';
import useSystemAdminRoute from '../User/useSystemAdminRoute';

interface SurveyLogsModuleDrawingsProps {}

interface Data {
  [key: string]: {
    hyve_module_id: string;
    hyve_module_name: string;
    cumulative_intelligence_gain: number;
    ranged_weight: number;
    won_via: string;
    user_id: string;
    user_email: string;
  };
}

const SurveyLogsModuleDrawings = (props: SurveyLogsModuleDrawingsProps) => {
  useSystemAdminRoute();
  let [success, setSuccess] = useState(false);
  let [data, setData] = useState<Data | null>(null);
  const { id } = useParams();

  useEffect(() => {
    hyveApi
      .create()
      .get(`/survey_logs/${id}/module_drawings`)
      .then(result => {
        setData(result.data);
        setSuccess(true);
      })
      .catch(error => {
        setSuccess(false);
        console.error(error);
      });
  }, [props, id]);

  return (
    <Fragment>
      {success === null ? 'Loading...' : ''}
      {!success ? 'Failed to load data' : ''}
      {success ? (
        <Fragment>
          {data ? (
            <Fragment>
              <h2>Module drawings</h2>
              Whenever survey generation is requested: for each user, a survey is generated for each module that the user is a valid contributor for. A survey
              is then selected in a weighted-random fashion based on the cumulative intelligence of the surveys. This random selection is done per user, the
              table below displays multiple drawings (you can filter by user if you want).
              <div className='survey-log-table'>
                <DataGrid
                  // autoHeight={true}
                  // autoPageSize={true}
                  rows={Object.keys(data).map(id => ({
                    id,
                    hyve_module_id: data![id]['hyve_module_id'], // It's unclear why TS can't infer that data can't be null.
                    hyve_module_name: data![id]['hyve_module_name'],
                    intelligence_gain: data![id]['cumulative_intelligence_gain'],
                    ranged_weight: data![id]['ranged_weight'],
                    won_via: data![id]['won_via'],
                    user_id: data![id]['user_id'],
                    user: data![id]['user_email'],
                    winner: parseFloat(data![id]['won_via']) > 0 ? '✅' : null,
                  }))}
                  columns={[
                    { field: 'winner', headerName: 'winner', width: 150 },
                    {
                      field: 'hyve_module_id',
                      headerName: 'Module id',
                      width: 150,
                    },
                    {
                      field: 'hyve_module_name',
                      headerName: 'Module',
                      width: 150,
                    },
                    {
                      field: 'intelligence_gain',
                      headerName: 'Intelligence Gain',
                      width: 200,
                    },
                    {
                      field: 'ranged_weight',
                      headerName: 'Ranged weight',
                      width: 200,
                    },
                    { field: 'won_via', headerName: 'Winning #', width: 150 },
                    { field: 'user_id', headerName: 'User id', width: 150 },
                    { field: 'user', headerName: 'User', width: 150 },
                  ]}
                  getRowClassName={params => (params.row.winner ? 'survey-log-table-winner' : '')}
                  slots={{ toolbar: GridToolbar }}
                />
              </div>
            </Fragment>
          ) : null}
        </Fragment>
      ) : null}
    </Fragment>
  );
};

export default SurveyLogsModuleDrawings;
