import { useEffect } from 'react';
import { useLocation } from 'react-router';

import { withErrorHandling } from './error';

import ReactGA from 'react-ga';

import { useAuthentication } from '../User/AuthenticationContext';
import useUser from '../User/useUser';
const sendPageView = location => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
};

export const useAnalytics = withErrorHandling(() => {
  const location = useLocation();
  const { authenticated } = useAuthentication();
  const { user } = useUser();

  const userId = authenticated ? user?.email : null;

  useEffect(() => {
    if (process.env.REACT_APP_GA_ID && process.env.REACT_APP_GA_ID !== '-1' && userId) {
      ReactGA.initialize(process.env.REACT_APP_GA_ID, {
        debug: false,
        titleCase: false,
        gaOptions: {
          userId: userId,
          redactEmail: false,
          siteSpeedSampleRate: 100,
        },
      });
      sendPageView(location.pathname);
    }
  }, [location, userId]);
});
