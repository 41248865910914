import { useState, createRef } from 'react';
import { useNavigate } from 'react-router';
import { Link, ListItemIcon, MenuItem, MenuProps } from '@mui/material';
import ChatRoundedIcon from '@mui/icons-material/ChatRounded';
import MapRoundedIcon from '@mui/icons-material/MapRounded';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import { useLayout } from '../UI/LayoutContext';
import SimpleMenu from '../UI/SimpleMenu';
import { useWorkspace } from '../Workspace/useWorkspace';

const MOBILE_GUIDE_ID = 'jWJ75IYDw594ZxOkcw5fDgq7GhA';
const DESKTOP_GUIDE_ID = 'TWXpTUSDsQRgdA6HVJ525lc5Yrk';

// Extend the window object
declare global {
  interface Window {
    Beacon?: any;
  }
}

interface HelpMenuProps extends MenuProps {
  open: boolean;
  onClose: () => void;
}

const HelpMenu = ({ open, onClose, ...props }: HelpMenuProps) => {
  const navigate = useNavigate();
  const layout = useLayout();
  const { workspace } = useWorkspace();

  const includeChatMenuItem = true; // layout === 'mobile';

  const handleHelpGuideClick = () => {
    const guideId = layout === 'mobile' ? MOBILE_GUIDE_ID : DESKTOP_GUIDE_ID;
    navigate(`/workspace/${workspace.id}?pendo=${guideId}`);
  };

  const handleChatClick = () => {
    window.Beacon('open');
  };

  return (
    <SimpleMenu open={open} onClose={onClose} {...props}>
      <MenuItem onClick={handleHelpGuideClick}>
        <ListItemIcon>
          <MapRoundedIcon />
        </ListItemIcon>
        Quick Guide
      </MenuItem>
      <MenuItem>
        <ListItemIcon>
          <HelpRoundedIcon />
        </ListItemIcon>
        <Link href='https://help.hyve.app/' color='inherit' target='_blank' rel='noreferrer'>
          Help Center
        </Link>
      </MenuItem>
      {includeChatMenuItem && (
        <MenuItem disabled={!window.Beacon} onClick={handleChatClick}>
          <ListItemIcon>
            <ChatRoundedIcon />
          </ListItemIcon>
          Chat With Us
        </MenuItem>
      )}
    </SimpleMenu>
  );
};

const helpMenuAnchorRef = createRef<HTMLDivElement>();
export const useHelpMenu = () => {
  const [helpMenuOpen, setHelpMenuOpen] = useState(false);
  return { helpMenuOpen, setHelpMenuOpen, helpMenuAnchorRef };
};

export default HelpMenu;
