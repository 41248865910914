import React, { useState } from 'react';

import CorporateFareRoundedIcon from '@mui/icons-material/CorporateFareRounded';
import GroupAddRoundedIcon from '@mui/icons-material/GroupAddRounded';

import { Divider, Icon, IconButton, ListItemIcon, MenuItem, Stack, Tooltip } from '@mui/material';
import SimpleMenu, { useMenu } from '../UI/SimpleMenu';
import BoltIconRounded from '@mui/icons-material/BoltRounded';

import NewClientDialog from '../Client/NewClientDialog';
import AccountNavigation from '../Navigation/AccountNavigation';
import NewProjectDialog from '../Project/NewProjectDialog';
import InviteDialog from '../Invitation/InviteDialog';
import Box from '@mui/material/Box';
import { useLayout } from '../UI/LayoutContext';
import useActiveFeatures from '../Module/useActiveFeatures';
import { useAddProject } from '../Projects/useAddProject';
import { useAI } from '../AI/AIContext';
import useKeyboardShortcut, { KeyboardShortcutTooltip } from '../UI/useKeyboardShortcut';

const WorkspaceActions = () => {
  const { open: addMenuOpen, setOpen: setAddMenuOpen, onClose, anchorRef } = useMenu();
  const [inviteDialogOpen, setInviteDialogOpen] = useState(false);
  const [newClientDialogOpen, setNewClientDialogOpen] = useState(false);
  const layout = useLayout();
  const { clientsActive } = useActiveFeatures();
  const { addProjectActions, addProjectCompany, addProjectDialogOpen, setAddProjectDialogOpen } = useAddProject('auto');
  const { open: aiOpen, setOpen: setAIOpen } = useAI();
  const { aiActive } = useActiveFeatures();

  useKeyboardShortcut(() => setAIOpen(true), ' ', { ctrl: true }, aiActive);

  return (
    <Stack className="workspace-actions" direction="row">
      {layout !== 'mobile' && aiActive && (
        <Stack p={1} justifyContent="center">
          <KeyboardShortcutTooltip title="Hyve AI" shortcut="^ Space">
            <IconButton onClick={() => setAIOpen(!aiOpen)}>
              <BoltIconRounded />
            </IconButton>
          </KeyboardShortcutTooltip>
        </Stack>
      )}

      <Divider orientation="vertical" sx={{ height: 'auto' }} />

      <Stack p={1} justifyContent="center">
        <Box ref={anchorRef}>
          <Tooltip title="Add" disableInteractive>
            <IconButton data-cy="workspace-add-button" id="add" onClick={() => setAddMenuOpen(true)}>
              <Icon>add_circle</Icon>
            </IconButton>
          </Tooltip>
        </Box>
      </Stack>

      <Divider orientation="vertical" sx={{ height: 'auto' }} />

      {layout !== 'mobile' && <AccountNavigation />}

      <SimpleMenu open={addMenuOpen} onClose={onClose} anchorEl={anchorRef.current}>
        {clientsActive && (
          <MenuItem id="add-client" onClick={() => setNewClientDialogOpen(true)}>
            <ListItemIcon>
              <CorporateFareRoundedIcon />
            </ListItemIcon>
            Add Client
          </MenuItem>
        )}

        {addProjectActions.map(action => (
          <MenuItem key={action.id} id={action.id} onClick={action.onSelect}>
            <ListItemIcon>{React.createElement(action.icon!)}</ListItemIcon>
            {action.label}
          </MenuItem>
        ))}

        <MenuItem id="invite-team-members" onClick={() => setInviteDialogOpen(true)}>
          <ListItemIcon>
            <GroupAddRoundedIcon />
          </ListItemIcon>
          Invite Teammates
        </MenuItem>
      </SimpleMenu>

      <NewClientDialog open={newClientDialogOpen} onClose={() => setNewClientDialogOpen(false)} />
      <NewProjectDialog key={addProjectCompany?.id} company={addProjectCompany} open={addProjectDialogOpen}
                        onClose={() => setAddProjectDialogOpen(false)} />
      <InviteDialog open={inviteDialogOpen} onClose={() => setInviteDialogOpen(false)} />
    </Stack>
  );
};

export default WorkspaceActions;
