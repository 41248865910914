import React, { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { ScopeInterface, UNSCOPED_SCOPE, useScopesForScorable } from './Scope';
import { Scorable } from '../Scores/Scorable';

const initialState = {
  scope: UNSCOPED_SCOPE,
  setScope: () => {},
  scopes: [],
};
const ScopeContext = createContext<ScopeContextType>(initialState);

interface ScopeProviderProps {
  scorable: Scorable;
  children: React.ReactNode;
}

export interface ScopeContextType {
  scope: ScopeInterface;
  setScope: (scope: ScopeInterface) => void;
  scopes: ScopeInterface[];
}

export const ScopeProvider = ({ scorable, children }: ScopeProviderProps) => {
  const { scopes, defaultScope } = useScopesForScorable(scorable);
  const location = useLocation();

  // Initialize scope with local storage or default
  const savedScope = JSON.parse(localStorage.getItem('selectedScope') || 'null');
  const isValidSavedScope = savedScope && scopes.some(scope => scope.id === savedScope.id);
  const initialScope = isValidSavedScope ? savedScope : location.state?.scope || defaultScope;

  const [scope, setScope] = useState(initialScope);

  // Save to local storage when scope changes
  useEffect(() => {
    localStorage.setItem('selectedScope', JSON.stringify(scope));
  }, [scope]);

  return <ScopeContext.Provider value={{ scope, setScope, scopes }}>{children}</ScopeContext.Provider>;
};

export default ScopeContext;
