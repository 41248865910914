import React, { Fragment } from 'react';
import Typography from '@mui/material/Typography';
import Stack, { StackProps } from '@mui/material/Stack';
import AppSurface from '../UI/AppSurface';
import OverflowActions from '../UI/OverflowActions';
import { ActionInterface } from '../UI/ActionInterface';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Unstable_Grid2';
import { Link as RouterLink } from 'react-router';
import { useLayout } from '../UI/LayoutContext';

type SurfaceVariant = 'primary' | 'secondary';

interface DashboardSurfaceProps extends StackProps {
  name?: string;
  variant?: SurfaceVariant;
  title?: string;
  subtitle?: React.ReactNode;
  actions?: ActionInterface[];
  overflowActions?: ActionInterface[];
}

export const DashboardSurface = ({ name, variant, title, subtitle, actions, overflowActions, children, ...props }: DashboardSurfaceProps) => {
  return (
    <AppSurface spacing={{ xs: 2, md: 4 }} p={{ xs: 2, md: 4 }} {...props}>
      <DashboardSurfaceHeader name={name} variant={variant} title={title} subtitle={subtitle} actions={actions} overflowActions={overflowActions} />
      {children}
    </AppSurface>
  );
};

interface DashboardSurfaceHeaderProps {
  name?: string;
  variant?: SurfaceVariant;
  title?: string;
  subtitle?: React.ReactNode;
  actions?: ActionInterface[];
  overflowActions?: ActionInterface[];
}

const DashboardSurfaceHeader = ({ name, variant = 'secondary', title, subtitle, actions = [], overflowActions }: DashboardSurfaceHeaderProps) => {
  const layout = useLayout();
  if (!title && !actions.length) {
    return null;
  }

  const mainElement = (
    <Stack height='100%' alignItems='center' spacing={1}>
      {title && (
        <Typography variant={variant === 'primary' ? 'h5' : 'h6'} textAlign='center' sx={{ textWrap: 'balance' }}>
          {title}
        </Typography>
      )}
      {subtitle && (
        <Typography component='div' color='neutral.main' sx={{ fontSize: '16px', fontWeight: 300 }}>
          {subtitle}
        </Typography>
      )}
    </Stack>
  );

  const actionsElement =
    layout === 'mobile' && actions.length > 1 ? (
      <OverflowActions name={name} actions={actions} />
    ) : (
      <Fragment>
        {actions.map((action, index) => (
          <Tooltip key={index} title={action.label}>
            <IconButton component={RouterLink} to={action.path!}>
              {React.createElement(action.icon!)}
            </IconButton>
          </Tooltip>
        ))}
        {overflowActions && <OverflowActions name={name} actions={overflowActions} />}
      </Fragment>
    );

  if (actions.length > 0 || (overflowActions && overflowActions.length > 0)) {
    return (
      <Grid container alignItems='center'>
        <Grid xs={2}></Grid>
        <Grid xs={8}>{mainElement}</Grid>
        <Grid xs={2} display='flex' justifyContent='end'>
          {actionsElement}
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container>
        <Grid xs={12}>{mainElement}</Grid>
      </Grid>
    );
  }
};
