import { useState } from 'react';
import Button from '@mui/material/Button';
import useWorkspace from '../Workspace/useWorkspace';
import { useAuthorizeSlack, useRevokeSlackToken, SlackUpdateStatus, SlackAuthType } from './useSlackIntegration';

interface SlackIntegrationControlsProps {
  type: SlackAuthType;
}

const SlackIntegrationControls = ({ type }: SlackIntegrationControlsProps) => {
  const [slackUpdateStatus, setSlackUpdateStatus] = useState<SlackUpdateStatus | null>(null);
  const { workspace, refetch } = useWorkspace();
  const { authorizeAppScopesURL, authorizeUserScopesURL } = useAuthorizeSlack();

  const revokeSlackToken = useRevokeSlackToken(type, workspace.id, refetch, setSlackUpdateStatus);

  const hasSlackAccess = type === 'app' ? workspace?.hasSlack : workspace?.user?.hasSlackUserAccess;
  const slackAuthenticationURL = type === 'app' ? authorizeAppScopesURL : authorizeUserScopesURL;

  const renderButton = () => {
    if (slackUpdateStatus === 'error') {
      return 'Error when attempting to revoke token';
    }

    if (hasSlackAccess) {
      return (
        <Button onClick={revokeSlackToken} disabled={slackUpdateStatus === 'revoking'}>
          {slackUpdateStatus === 'revoking' ? 'Revoking...' : type === 'app' ? 'Remove from Slack' : 'Revoke'}
        </Button>
      );
    }

    return <Button href={slackAuthenticationURL}>{type === 'app' ? 'Add to Slack' : 'Authorize'}</Button>;
  };

  return <>{renderButton()}</>;
};

export default SlackIntegrationControls;
