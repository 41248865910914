import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router';
import { useForm, Controller } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { TextField, Typography } from '@mui/material';

import PageStyle from '../UI/PageStyle';
import BrandedForm, { SubmitButton } from '../UI/BrandedForm';
import { useAuthentication } from './AuthenticationContext';
import { AxiosError } from 'axios';

interface ErrorResponse {
  error: string;
}

const schema = z.object({
  email: z.string().email({ message: 'Email must be valid' }).min(1, 'Email is required'),
  password: z.string().min(1, 'Password is required'),
});

type SchemaType = z.infer<typeof schema>;

const SignIn = () => {
  const { signIn, loading, authenticated } = useAuthentication();

  const navigate = useNavigate();

  useEffect(() => {
    if (authenticated) {
      navigate('/');
    }
  }, [authenticated, navigate]);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<SchemaType>({
    resolver: zodResolver(schema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit = async ({ email, password }: SchemaType) => {
    setErrorMessage(null);

    try {
      await signIn(email, password);
      // console.log('signed in user', user);

      //      authenticate(); // TODO: testing removing this. the signIn function seems to be handling it
    } catch (error: any) {
      console.error(error);
      const axiosError = error as AxiosError<ErrorResponse>;

      if (!axiosError.response) {
        let errorDescription = 'Network error: Unable to connect to the server.';

        if (axiosError.code) {
          errorDescription += ` Error code: ${axiosError.code}`;
        }

        setErrorMessage(errorDescription);
      } else if (axiosError.response?.status === 401) {
        setErrorMessage(axiosError.response.data.error);
      } else {
        setErrorMessage('Error signing in. Please try again.');
      }
    }
  };

  if (loading) {
    return <PageStyle design='simple' loading={true} />;
  }

  return (
    <PageStyle design='branded'>
      <BrandedForm onSubmit={handleSubmit(onSubmit)} errorMessage={errorMessage}>
        <Controller
          name='email'
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              id='email'
              type='email'
              label='Email'
              variant='outlined'
              fullWidth
              autoFocus
              InputLabelProps={{
                shrink: true,
              }}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />

        <Controller
          name='password'
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              id='password'
              type='password'
              label='Password'
              variant='outlined'
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />

        <SubmitButton isSubmitting={isSubmitting}>Sign In</SubmitButton>

        <Typography variant='body2'>
          <Link to={'/forgot-password'}>Forgot Password?</Link>
        </Typography>
      </BrandedForm>
    </PageStyle>
  );
};

export default SignIn;
