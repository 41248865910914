import React, { useState } from 'react';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import StyledMenu from '../UI/StyledMenu';
import { ActionInterface } from './ActionInterface';
import { Divider, IconButton, ListItemIcon, MenuItem, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router';

export type ActionListType = Array<ActionInterface | 'divider'>;

export interface OverflowActionsProps {
  name?: string;
  className?: string; // for testing
  actions: ActionListType;
}

const OverflowActions = ({ name, actions = [], ...props }: OverflowActionsProps) => {
  const [menuAnchor, setMenuAnchor] = useState<Element | null>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  // Filter any null or undefined actions
  actions = actions.filter(action => action);
  if (!actions.length) {
    return null;
  }

  const handleMenuSelect = (event: React.MouseEvent, action: ActionInterface) => {
    event.stopPropagation();
    event.preventDefault();

    setMenuOpen(false);
    if (action.onSelect) {
      action.onSelect();
    } else if (action.path) {
      navigate(action.path);
    }
  };

  return (
    <Stack alignItems='end' data-cy='actions'>
      <IconButton
        sx={{ width: '40px' }}
        name={name ? `${name}-actions` : undefined}
        onClick={event => {
          event.stopPropagation();
          event.preventDefault();
          setMenuAnchor(event.target as Element);
          setMenuOpen(true);
        }}
        {...props}
      >
        <MoreVertRoundedIcon />
      </IconButton>

      <StyledMenu
        anchorEl={menuAnchor}
        keepMounted
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {actions.map((action, index) => {
          if (action === 'divider') {
            return <Divider key={index} />;
          } else {
            return (
              <MenuItem key={index} disabled={action.disabled} onClick={event => handleMenuSelect(event, action)}>
                {action.icon && <ListItemIcon>{React.createElement(action.icon, { color: action.color })}</ListItemIcon>}
                <Typography color={action.color}>{action.label}</Typography>
              </MenuItem>
            );
          }
        })}
      </StyledMenu>
    </Stack>
  );
};

export default OverflowActions;
