import { useState, createRef } from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate, useOutletContext, useParams } from 'react-router';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import { aspectIcon } from './AspectIcon';
import { computeScoreLevel } from '../Scores/Score';
import SimpleMenu from '../UI/SimpleMenu';
import { SectionHeader, SectionHeaderIcon, SectionHeaderTitle, SectionHeaderActions } from '../UI/Section';
import { Aspect } from './Aspect';
import { ModuleInterface } from '../Module/Module';
import AspectContent from './AspectContent';
import { Scorable } from '../Scores/Scorable';

const aspectMenuRef = createRef<Element>();

interface AspectDetailProps {
  scorable: Scorable;
  module: ModuleInterface;
  aspects: Aspect[];
}

const AspectDetail = () => {
  const { scorable, module } = useOutletContext<AspectDetailProps>();
  const { aspects } = module;
  //  console.log(module);

  const navigate = useNavigate();
  const { aspectId: id } = useParams();
  const aspectIndex = aspects.findIndex(aspect => aspect.id === id);
  const aspect = aspects[aspectIndex];

  const [aspectMenuOpen, setAspectMenuOpen] = useState(false);
  const handleAspectSelection = (aspect: Aspect) => {
    navigate(`../${aspect.id}`);
  };

  const handlePrevious = () => {
    navigate(`../${aspects[aspectIndex - 1].id}`);
  };
  const handleNext = () => {
    navigate(`../${aspects[aspectIndex + 1].id}`);
  };

  //  console.log(scorable)

  return (
    <>
      <section>
        <StyledAspectDetail>
          <SectionHeader>
            <IconButton edge='start' onClick={() => navigate('..')}>
              <Icon className='back-button'>arrow_back</Icon>
            </IconButton>
            <SectionHeaderIcon>
              <Icon className='title-icon'>{aspectIcon(module.__typename, aspect.name)}</Icon>
            </SectionHeaderIcon>
            <SectionHeaderTitle>
              <Box ref={aspectMenuRef}>
                <Button className='title text-button' onClick={() => setAspectMenuOpen(true)}>
                  {aspect.name}
                  <Icon>arrow_drop_down</Icon>
                </Button>
              </Box>
              <SimpleMenu open={aspectMenuOpen} onClose={() => setAspectMenuOpen(false)} anchorEl={aspectMenuRef.current}>
                {aspects.map(menuAspect => (
                  <MenuItem key={menuAspect.id} onClick={() => handleAspectSelection(menuAspect)} selected={menuAspect.id === aspect.id}>
                    <ListItemIcon>
                      <Icon style={{ color: computeScoreLevel(menuAspect.health).color }}>{aspectIcon(module.__typename, menuAspect.name)}</Icon>
                    </ListItemIcon>
                    {menuAspect.name}
                  </MenuItem>
                ))}
              </SimpleMenu>
            </SectionHeaderTitle>
            <SectionHeaderActions>
              <IconButton onClick={handlePrevious} disabled={aspectIndex === 0}>
                <Icon>arrow_back</Icon>
              </IconButton>
              <IconButton onClick={handleNext} disabled={aspectIndex === aspects.length - 1}>
                <Icon>arrow_forward</Icon>
              </IconButton>
            </SectionHeaderActions>
          </SectionHeader>

          <AspectContent scorable={scorable} module={module} aspect={aspect} />
          {/*<AspectSummary scorable={scorable} module={module} aspect={aspect} showRecommendation />*/}
        </StyledAspectDetail>
      </section>
    </>
  );
};

const StyledAspectDetail = styled('div')`
  .aspect-summary {
    margin-top: 32px;
  }

  .aspect-section-heading {
    display: block;
    font-size: 1.17em;
    font-weight: bold;
    margin-block-start: 1em;
    margin-block-end: 1em;
  }

  .divider {
    margin: 40px 0;
  }

  .back-button {
    cursor: pointer;
    color: #666;
    &:hover {
      color: #000;
    }
  }
`;

export default AspectDetail;
