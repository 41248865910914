import React, { Fragment } from 'react';

import Stack from '@mui/material/Stack';
import { useLayout } from '../UI/LayoutContext';
import { usePrimaryNavigation } from './usePrimaryNavigation';
import MobileLayout from './MobileLayout';
import { DrawerProvider } from './DrawerContext';
import DesktopLayout from './DesktopLayout';

const WorkspaceLayout = ({ children }: { children: React.ReactNode }) => {
  const layout = useLayout();
  const { activeArea } = usePrimaryNavigation();

  if (!activeArea) {
    return <Fragment>{children}</Fragment>;
  }

  return (
    <Stack data-component='WorkspaceLayout' height='100vh'>
      <DrawerProvider>{layout !== 'mobile' ? <DesktopLayout>{children}</DesktopLayout> : <MobileLayout>{children}</MobileLayout>}</DrawerProvider>
    </Stack>
  );
};

export default WorkspaceLayout;
