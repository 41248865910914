import { gql, useQuery } from '@apollo/client';
import { useLocation, useResolvedPath } from 'react-router';

import { Box, Divider, Grid, Stack } from '@mui/material';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';

import { getCategory } from '../Category/useCategory';
import { DashboardSurface } from '../Dashboard/Dashboard';
import { useTimeframe } from '../User/useTimeframe';
import { useScope } from '../Scope/useScope';
import { useClientContext } from './ClientContext';
import { ClientInterface } from './ClientInterface';
import { ProjectsModuleDashboardDetails } from '../Projects/ProjectsModuleDashboard';
import DashboardSummary from '../Dashboard/DashboardSummary';
import NeedsAttention from '../Dashboard/NeedsAttention';
import CategoryModuleBreakdown from '../Category/CategoryModuleBreakdown';
import { DashboardCard } from '../Dashboard/DashboardCard';
import { MembershipDashboardStats } from '../Dashboard/DashboardStats';
import { LOW_MEDIUM_PROJECTS_FRAGMENT } from '../Projects/ProjectCollector';
import { CATEGORY_SCORE_FIELDS_FRAGMENT } from '../Category/Category';
import { scorableProjectBreakdownFragment, SCORABLE_SCORE_FIELDS_FRAGMENT } from '../Scores/ScorableBase';
import useActiveFeatures from '../Module/useActiveFeatures';
import IntelligenceNotice from '../Intelligence/IntelligenceNotice';
import ClientImportanceRating from './ClientImportanceRating';

const ClientDashboardOverview = () => {
  const { client } = useClientContext();
  const { scope } = useScope();
  const { days } = useTimeframe();
  const { id } = client;
  const projectsPath = useResolvedPath('projects').pathname;
  const { clientProjectsActive } = useActiveFeatures();
  const { pathname } = useLocation();

  const { data } = useQuery(CLIENT_SCORES_QUERY, { variables: { id, ...scope.variables, days } });

  const clientScores: ClientInterface = data?.client;
  if (!data) {
    return null;
  }

  const categoryWithScores = getCategory(clientScores, 'ClientsCategory');

  return (
    <>
      <IntelligenceNotice scorable={client} rounded />
      <DashboardSurface title={client.name} subtitle={<ClientDashboardSubtitle />} variant='primary'>
        <DashboardSummary
          scorable={data?.client}
          scorableLabel={client.internal ? 'Internal Project' : 'Client'}
          scopePrefix={scope.type === 'Unscoped' ? '' : scope.prefix}
          icon={BusinessRoundedIcon}
        />

        <ClientDashboardStats client={clientScores} />

        <Stack>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={6}>
              <Stack spacing={4}>
                <DashboardCard>
                  <CategoryModuleBreakdown category={categoryWithScores} parentPath={pathname} />
                </DashboardCard>
                {clientProjectsActive && <ProjectsModuleDashboardDetails projectCollector={clientScores} projectsPath={projectsPath} />}
              </Stack>
            </Grid>
            <Grid item xs={12} lg={6} display='flex'>
              <NeedsAttention scorables={categoryWithScores.needsAttention} category={categoryWithScores} />
            </Grid>
          </Grid>
        </Stack>
      </DashboardSurface>
    </>
  );
};

export default ClientDashboardOverview;

const ClientDashboardSubtitle = () => {
  const { client } = useClientContext();

  return (
    <Stack direction='row' spacing={2} divider={<Divider orientation='vertical' flexItem />}>
      <Stack direction='row' alignItems='center' spacing={0.5}>
        <Box>Importance:</Box>
        <ClientImportanceRating client={client} size='small' />
      </Stack>
    </Stack>
  );
};

const ClientDashboardStats = ({ client }: { client: ClientInterface }) => {
  return <MembershipDashboardStats scorable={client} intelligenceLabel={'Client Hyve Intelligence'} />;
};

const CLIENT_SCORES_QUERY = gql`
  query ClientScores($id: ID!, $scope: ScopeInput, $days: Int) {
    client(id: $id, scope: $scope) {
      id
      name
      internal
      imageUrl

      memberStats {
        count
      }

      ...ScoreFields

      categories {
        ...CategoryScoreFields
      }
      ...ClientProjectBreakdown
      ...LowMediumProjects
    }
  }
  ${SCORABLE_SCORE_FIELDS_FRAGMENT}
  ${CATEGORY_SCORE_FIELDS_FRAGMENT}
  ${LOW_MEDIUM_PROJECTS_FRAGMENT}
  ${scorableProjectBreakdownFragment('Client')}
`;
