import ClientList from '../Client/ClientList';
import ListRoundedIcon from '@mui/icons-material/ListRounded';
import React from 'react';
import { Outlet } from 'react-router';
import { useTeamContext } from './TeamContext';

const TeamClients = () => {
  const { team } = useTeamContext();

  const clientsListTab = {
    key: 'list',
    icon: ListRoundedIcon,
    label: 'Client List',
    value: 'client-list',
    path: `list`,
    component: ClientList,
  };

  return <Outlet context={{ additionalTabs: [clientsListTab], clientContainer: team }} />;
};

export default TeamClients;
