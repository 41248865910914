import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router';

import { Avatar, AvatarGroup, Icon, Link, ListItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { ElementSize, GridCellParams, GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridRowsProp, GridSortModel } from '@mui/x-data-grid';

import ClientActions from '../Client/ClientActions';
import ClientAvatar from '../Client/ClientAvatar';
import { ScopeInterface } from '../Scope/Scope';
import EntityScoreIcon from '../Scores/EntityScoreIcon';

import { Layout, useLayout } from '../UI/LayoutContext';
import StyledDataGrid, { DATA_GRID_COLUMN_HEADER_STYLES, getColumnVisibilityModel } from '../UI/StyledDataGrid';
import { useScope } from '../Scope/useScope';
import { ClientInterface } from './ClientInterface';
import { useWorkspace } from '../Workspace/useWorkspace';
import { WorkspaceInterface } from '../Workspace/WorkspaceInterface';
import { UserInterface } from '../User/User';
import { ClientProvider } from './ClientContext';
import useActiveFeatures from '../Module/useActiveFeatures';
import { getModule } from '../Module/useModule';
import { useCategory } from '../Category/useCategory';
import { Typography } from '@mui/material';
import { computeScoreLevel } from '../Scores/Score';
import ClientImportanceRating from './ClientImportanceRating';
import { importanceSortComparator } from '../Scores/ImportanceRating';

type ColumnField =
  | 'name'
  | 'importance'
  | 'score'
  | 'products & services'
  | 'relationships'
  | 'project management'
  | 'intelligence'
  | 'owner'
  | 'members'
  | 'projectMembership'
  | 'actions';
const COLUMN_PRIORITY: ColumnField[] = [
  'name',
  'actions',
  'score',
  'importance',
  'products & services',
  'relationships',
  'project management',
  'owner',
  'intelligence',
  'projectMembership',
  'members',
];
const ROWS_PER_PAGE = 50;

interface ClientDataGridProps {
  clients: ClientInterface[];
  loading?: boolean;
  refetch: () => Promise<any>;
  slots: Object;
  slotProps: Object;
}

type ClientRow = {
  client: ClientInterface;
  projectMembership: boolean;
};

const ClientDataGrid = ({ clients, refetch, ...props }: ClientDataGridProps) => {
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>();
  const { scope } = useScope();
  const { workspace } = useWorkspace();
  const navigate = useNavigate();
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'score', sort: 'asc' }]);
  const layout = useLayout();

  const columns = useColumns(workspace, scope, layout, refetch);

  const rows: GridRowsProp = clients.map(client => {
    const owner = client.owners[0];
    const ownerName = owner ? `${owner.firstName} ${owner.lastName}` : null;

    let projectMembership;
    switch (scope.type) {
      case 'Personal':
      case 'Unscoped':
        projectMembership = client.projectStats.personalProjectCount;
        break;
      case 'Team':
        projectMembership = client.projectStats.scopedProjectCount;
        break;
      default:
        throw new Error('Unexpected scope type');
    }

    return {
      id: client.id,
      client: client,
      name: client.name,
      importance: client.importance,
      owner: ownerName,
      members: client.users,
      projectMembership: projectMembership,
      score: client.health || Number.MAX_VALUE,
      intelligence: client.intelligence,
    };
  });

  const handleCellClick = (params: GridCellParams) => {
    const field = params.field as ColumnField;
    const { row } = params;
    const { client } = row;
    const clientPath = `/workspace/${workspace.id}/clients/${client.id}`;
    switch (field) {
      case 'name':
        navigate(clientPath);
        break;
      case 'owner':
      case 'members':
        navigate(`${clientPath}/members`);
        break;
      case 'projectMembership':
        navigate(`${clientPath}/projects`);
        break;
      case 'products & services':
        navigate(`${clientPath}/modules/${getModule(client, 'ClientsProductsAndServicesModule')!.id}`);
        break;
      case 'relationships':
        navigate(`${clientPath}/modules/${getModule(client, 'ClientsRelationshipModule')!.id}`);
        break;
      case 'project management':
        navigate(`${clientPath}/modules/${getModule(client, 'ClientsProductsAndServicesModule')!.id}`);
        break;
      case 'importance':
        break;
      case 'actions':
        break;
      default:
        navigate(clientPath);
    }
  };

  const handleResize = (containerSize: ElementSize) => {
    setColumnVisibilityModel(getColumnVisibilityModel(columns, COLUMN_PRIORITY, containerSize.width));
  };

  return (
    <StyledDataGrid
      rowHeight={70}
      rows={rows}
      columns={columns}
      columnVisibilityModel={columnVisibilityModel}
      sortingOrder={['asc', 'desc']}
      hideFooter={rows.length <= ROWS_PER_PAGE}
      disableColumnMenu={true}
      disableRowSelectionOnClick={true}
      sortModel={sortModel}
      onSortModelChange={model => {
        if (JSON.stringify(model) !== JSON.stringify(sortModel)) {
          setSortModel(model);
        }
      }}
      onResize={handleResize}
      onCellClick={handleCellClick}
      sx={{ ...DATA_GRID_COLUMN_HEADER_STYLES }}
      {...props}
    />
  );
};

type ClientGridColDef = GridColDef & {
  field: ColumnField;
  hidden?: boolean;
};

const useColumns = (workspace: WorkspaceInterface, scope: ScopeInterface, layout: Layout, refetch: () => Promise<any>): ClientGridColDef[] => {
  const { clientProjectsActive, clientProductsAndServicesActive, clientRelationshipsActive } = useActiveFeatures();
  const { category } = useCategory('ClientsCategory');
  const multipleModulesActive = category.modules.length > 1;

  const columns: ClientGridColDef[] = [];
  const nameColumn: ClientGridColDef = {
    field: 'name',
    headerName: 'Client Name',
    minWidth: 180,
    hideable: false,
    flex: 1,
    renderCell: (cellValues: GridCellParams<ClientRow>) => {
      const { client } = cellValues.row;
      const { archived } = client;

      return (
        <ListItem key={client.id}>
          <ListItemIcon>
            <ClientAvatar client={client} />
          </ListItemIcon>
          <ListItemText
            primary={
              <Link component={RouterLink} to={`/workspace/${workspace.id}/clients/${client.id}`}>
                {client.name}
              </Link>
            }
          />
          {archived && (
            <Icon style={{ margin: 0 }} className='list-item-status-icon'>
              archived
            </Icon>
          )}
        </ListItem>
      );
    },
  };
  columns.push(nameColumn);

  const scoreColumn: ClientGridColDef = {
    field: 'score',
    headerName: 'Score',
    width: 100,
    // headerAlign: 'center',
    // align: 'center',
    renderCell: (cellValues: GridRenderCellParams<ClientRow>) => {
      const { client } = cellValues.row;
      return <EntityScoreIcon score={client.health} filled={client.member} />;
    },
  };
  columns.push(scoreColumn);

  const importanceColumn: ClientGridColDef = {
    field: 'importance',
    headerName: 'Importance',
    width: 120,
    sortingOrder: ['desc', 'asc'],
    sortComparator: importanceSortComparator,
    // headerAlign: 'center',
    // align: 'center',
    renderCell: (cellValues: GridRenderCellParams<ClientRow>) => {
      const { client } = cellValues.row;
      return <ClientImportanceRating client={client} size='small' />;
    },
  };
  columns.push(importanceColumn);

  const productsAndServicesColumn: ClientGridColDef = {
    field: 'products & services',
    headerName: 'Products & Services',
    width: 120,
    // align: 'center',
    renderCell: (cellValues: GridRenderCellParams<ClientRow>) => {
      const { client } = cellValues.row;
      const module = getModule(client, 'ClientsProductsAndServicesModule');
      const score = module?.health; // The cache may not have this module loaded.
      // console.log('Products & Services', client, score);
      const color = computeScoreLevel(score).color;
      return <Typography color={color}>{score || '-'}</Typography>;
    },
  };
  if (multipleModulesActive && clientProductsAndServicesActive) {
    columns.push(productsAndServicesColumn);
  }

  const relationshipsColumn: ClientGridColDef = {
    field: 'relationships',
    headerName: 'Relationships',
    width: 120,
    // align: 'center',
    renderCell: (cellValues: GridRenderCellParams<ClientRow>) => {
      const { client } = cellValues.row;
      const score = getModule(client, 'ClientsRelationshipModule')!.health;
      // console.log('Relationships', client, score);
      const color = computeScoreLevel(score).color;
      return <Typography color={color}>{score || '-'}</Typography>;
    },
  };
  if (multipleModulesActive && clientRelationshipsActive) {
    columns.push(relationshipsColumn);
  }

  const projectManagementColumn: ClientGridColDef = {
    field: 'project management',
    headerName: 'Project Management',
    width: 120,
    // align: 'center',
    renderCell: (cellValues: GridRenderCellParams<ClientRow>) => {
      const { client } = cellValues.row;
      const score = getModule(client, 'ClientsProjectsModule')!.health;
      // console.log('Project Management', client, score);
      const color = computeScoreLevel(score).color;
      return <Typography color={color}>{score || '-'}</Typography>;
    },
  };
  if (multipleModulesActive && clientProjectsActive) {
    columns.push(projectManagementColumn);
  }

  const intelligenceColumn: ClientGridColDef = {
    field: 'intelligence',
    headerName: 'Intelligence',
    width: 120,
    // align: 'center',
    renderCell: (cellValues: GridRenderCellParams<ClientRow>) => {
      const { client } = cellValues.row;
      return client.intelligence || '-';
    },
  };
  columns.push(intelligenceColumn);

  const ownerColumn: ClientGridColDef = {
    field: 'owner',
    headerName: 'Owner',
    width: 120,
    valueGetter: ({ value }) => value || '-',
  };
  columns.push(ownerColumn);

  const membersColumn: ClientGridColDef = {
    field: 'members',
    headerName: 'Members',
    width: 120,
    sortComparator: (a: UserInterface[], b: UserInterface[]) => a.length - b.length,
    renderCell: (cellValues: GridCellParams<ClientRow>) => {
      const { client } = cellValues.row;
      return client.users.length ? (
        <AvatarGroup max={3}>
          {client.users.map(user => (
            <Tooltip key={user.id} title={`${user.firstName} ${user.lastName}`}>
              <Avatar alt={`${user.firstName} ${user.lastName}`} src={user.avatar}>
                {user.firstName[0] + user.lastName[0]}
              </Avatar>
            </Tooltip>
          ))}
        </AvatarGroup>
      ) : (
        '-'
      );
    },
  };
  columns.push(membersColumn);

  const projectMembershipColumn: ClientGridColDef = {
    field: 'projectMembership',
    headerName: getProjectsLabel(scope, layout),
    width: layout === 'desktop' ? 120 : 120,
    hidden: !clientProjectsActive,
    renderCell: (cellValues: GridCellParams<ClientRow>) => {
      const { client, projectMembership } = cellValues.row;
      const message = client.projectStats.allProjectsCount ? `${projectMembership} of ${client.projectStats.allProjectsCount} projects` : 'No projects';
      return (
        <div className='project-membership-cell'>
          <Link component='button'>{message}</Link>
        </div>
      );
    },
  };
  if (clientProjectsActive) {
    columns.push(projectMembershipColumn);
  }

  const actionsColumn: ClientGridColDef = {
    field: 'actions',
    headerName: ' ',
    width: 64,
    sortable: false,
    hideable: false,
    renderCell: (cellValues: GridRenderCellParams<ClientRow>) => {
      const { client } = cellValues.row;
      return (
        <ClientProvider client={client} refetch={refetch}>
          <ClientActions includeNavigationActions />
        </ClientProvider>
      );
    },
  };
  columns.push(actionsColumn);

  return columns;
};

const getProjectsLabel = (scope: ScopeInterface, layout: Layout) => {
  let label;
  switch (scope.type) {
    case 'Unscoped':
    case 'Personal':
      label = layout === 'mobile' ? 'Membership' : 'Project Membership';
      break;
    case 'Team':
      label = 'Team Projects';
      break;
    default:
      throw new Error(`Unexpected scope type: ${scope.type}`);
  }
  return label;
};

export default ClientDataGrid;
