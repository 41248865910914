import React, { useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router';
import { gql } from '@apollo/client';
import classnames from 'classnames';

import Container from '@mui/material/Container';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import AspectCard from '../Aspect/AspectCard';
import { computeScoreLevel, SCORE_HIGH, SCORE_MEDIUM, SCORE_LOW, SCORE_COLLECTING, ScoreLevel } from '../Scores/Score';
import { SectionHeader, SectionHeaderTitle, SectionHeaderActions } from '../UI/Section';
import { ModuleInterface } from '../Module/Module';
import { Divider, Typography } from '@mui/material';
import { Aspect } from './Aspect';
import AspectContent from './AspectContent';
import { Scorable } from '../Scores/Scorable';

const ASPECT_HEALTH_LEVELS = [SCORE_LOW, SCORE_MEDIUM, SCORE_HIGH, SCORE_COLLECTING];
type AspectLayout = 'card' | 'list';

const AspectList = () => {
  const navigate = useNavigate();
  const { scorable, module } = useOutletContext<{ scorable: Scorable; module: ModuleInterface }>();
  let { aspects } = module;
  const [view, setView] = useState<AspectLayout>('card');

  const handleDetailClick = (aspectId: string) => {
    navigate(`${aspectId}`);
  };

  return (
    <Stack pb={1}>
      <SectionHeader>
        <SectionHeaderTitle>Aspects</SectionHeaderTitle>

        <SectionHeaderActions>
          <IconButton className={classnames({ selected: view === 'list' })} onClick={() => setView('list')}>
            <Icon>reorder</Icon>
          </IconButton>
          <IconButton className={classnames({ selected: view === 'card' })} onClick={() => setView('card')}>
            <Icon>apps</Icon>
          </IconButton>
        </SectionHeaderActions>
      </SectionHeader>

      <Container>
        <Stack spacing={3} pb={3} py={2} divider={<Divider flexItem />}>
          {ASPECT_HEALTH_LEVELS.map(level => {
            const levelAspects = aspects.filter(aspect => level === computeScoreLevel(aspect.health));
            if (levelAspects.length) {
              return (
                <Stack key={level.key} spacing={2}>
                  <Typography>{healthLevelMessage(level)}</Typography>
                  <Stack>
                    {view === 'card' && <AspectCards scorable={scorable} module={module} aspects={levelAspects} onDetailClick={handleDetailClick} />}
                    {view === 'list' && <AspectListItems scorable={scorable} module={module} aspects={levelAspects} />}
                  </Stack>
                </Stack>
              );
            } else {
              return null;
            }
          })}
        </Stack>
      </Container>
    </Stack>
  );
};

interface AspectCardsProps {
  scorable: Scorable;
  module: ModuleInterface;
  aspects: Aspect[];
  onDetailClick: (aspectId: string) => void;
}

const AspectCards = ({ scorable, module, aspects, onDetailClick }: AspectCardsProps) => {
  return (
    <Stack useFlexGap gap={2} flexWrap='wrap' direction='row' spacing={2}>
      {aspects.map(aspect => (
        <AspectCard
          // key={aspect.id}
          key={aspect.id}
          scorable={scorable}
          module={module}
          aspect={aspect}
          onClick={() => onDetailClick(aspect.id)}
        />
      ))}
    </Stack>
  );
};

interface AspectListItemsProps {
  scorable: Scorable;
  module: ModuleInterface;
  aspects: Aspect[];
}

const AspectListItems = ({ scorable, module, aspects }: AspectListItemsProps) => {
  return (
    <div className='aspect-list-items'>
      {aspects.map(aspect => (
        <AspectContent key={aspect.id} scorable={scorable} module={module} aspect={aspect} />
      ))}
    </div>
  );
};

const healthLevelMessage = (scoreLevel: ScoreLevel) => {
  switch (scoreLevel) {
    case SCORE_HIGH:
      return 'Great job! These are looking good:';
    case SCORE_MEDIUM:
      return 'These aspects can use some improvement:';
    case SCORE_LOW:
      return 'The following aspects need close attention:';
    case SCORE_COLLECTING:
      return "We're still collecting intelligence for the following aspects:";
    default:
      throw new Error(`Unexpect health level: ${scoreLevel}`);
  }
};

export const ASPECT_SCORES_FRAGMENT = gql`
  fragment AspectScores on Aspect {
    id
    name
    description
    health
    healthDelta(days: $days)
    intelligence
    recommendations {
      preamble
      summary
      entries {
        name
        description
      }
    }
    aspectMetrics {
      id
      name
      health
    }
  }
`;

export const MODULE_ASPECT_SCORES_FRAGMENT = gql`
  fragment ModuleAspectScores on ModuleInterface {
    id
    name
    type
    aspects {
      ...AspectScores
    }
  }
  ${ASPECT_SCORES_FRAGMENT}
`;

export default AspectList;
