import React from 'react';
import { gql } from '@apollo/client';
import ProjectList from '../Projects/ProjectList';
import useProjectList, { PROJECT_COLLECTOR_FRAGMENT } from '../Projects/useProjectList';
import WorkspaceSecondaryNavigation from '../Workspace/WorkspaceSecondaryNavigation';
import PageStyle from '../UI/PageStyle';
import { useWorkspace } from './useWorkspace';
import { useScope } from '../Scope/useScope';
import HiveRoundedIcon from '@mui/icons-material/HiveRounded';
import Avatar from '@mui/material/Avatar';

const WorkspaceProjects = () => {
  const { workspace } = useWorkspace();
  const projectListProps = useProjectList(WORKSPACE_PROJECTS_QUERY, { id: workspace.id }, data => data.workspace);

  return (
    <React.Fragment>
      <ProjectsSecondaryNavigation />
      <PageStyle design="simple" theme="dark">
        <ProjectList mode="auto" {...projectListProps} />
      </PageStyle>
    </React.Fragment>
  );
};

const ProjectsSecondaryNavigation = () => {
  const { scopes } = useScope();
  const scopeOptions = scopes.map(scope => ({
    label: `${scope.selectorPrefix} Projects`,
    scope: scope,
    count: undefined,
  }));
  const iconElement = (
    <Avatar variant="rounded">
      <HiveRoundedIcon />
    </Avatar>
  );
  return <WorkspaceSecondaryNavigation scopeOptions={scopeOptions} iconElement={iconElement}
                                       includeUnscopedForAllMembers={true} />;
};

export default WorkspaceProjects;

export const WORKSPACE_PROJECTS_QUERY = gql`
  query WorkspaceProjects($id: ID!, $healthLevel: [HealthLevel!], $statuses: [ProjectStatus!], $scope: ScopeInput, $archived: [Boolean!]) {
    workspace(id: $id, scope: $scope) {
      id
      ...ProjectCollector
    }
  }
  ${PROJECT_COLLECTOR_FRAGMENT}
`;
