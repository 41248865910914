import React, { Fragment, useState } from 'react';
import { Link as RouterLink } from 'react-router';
import { useTheme } from '@mui/material/styles';

import { Divider, Stack, Typography } from '@mui/material';
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import WaterDropRoundedIcon from '../Icons/WaterDropRoundedIcon';

import { DashboardCard } from './DashboardCard';
import { MembershipInterface } from '../Membership/Membership';
import { useLayout } from '../UI/LayoutContext';
import { IconType } from '../UI/Icon';
import { ScoreDimensionBase } from '../Scores/ScoreDimensionBase';
import ButtonBase from '@mui/material/ButtonBase';
import { IntelligenceModal } from '../Scores/IntelligenceModal';
import { Scorable } from '../Scores/Scorable';
import { ScoreDimension } from '../Scores/ScoreDimension';

interface DashboardStatsProps {
  children: React.ReactNode;
  direction?: 'row' | 'column';
}

const VERTICAL_LAYOUTS = ['mobile', 'tablet', 'laptop'];

export const DashboardStats = ({ children, direction = 'row' }: DashboardStatsProps) => {
  const layout = useLayout();
  if (VERTICAL_LAYOUTS.includes(layout)) {
    return <DashboardCard divider={<Divider />}>{children}</DashboardCard>;
  } else {
    return (
      <Stack flex={1} direction={direction} spacing={4}>
        {children}
      </Stack>
    );
  }
};

interface DashboardStatProps {
  icon: IconType;
  label: string;
  value: string | number;
  valueLabel?: string;
  valueColor?: string;
  onClick?: () => void;
  path?: string;
}

export const DashboardStat = ({ icon, label, value, valueLabel, valueColor, onClick, path }: DashboardStatProps) => {
  const layout = useLayout();
  const theme = useTheme();

  let buttonProps;
  if (onClick) {
    buttonProps = { onClick };
  } else if (path) {
    buttonProps = { to: path, component: RouterLink };
  } else {
    buttonProps = { disabled: true };
  }

  if (VERTICAL_LAYOUTS.includes(layout)) {
    return (
      <ButtonBase {...buttonProps} sx={{ width: '100%', height: '100%' }}>
        <Stack direction='row' spacing={1} width='100%'>
          {React.createElement(icon, { sx: { fontSize: '20px' } })}
          <Typography flex={1} variant='body2' textAlign='left'>
            {label}
          </Typography>
          <Typography color={valueColor}>{value}</Typography>
        </Stack>
      </ButtonBase>
    );
  }

  return (
    <ButtonBase {...buttonProps} sx={{ width: '100%', height: '100%' }}>
      <DashboardCard
        direction='row'
        flex={1}
        height='100%'
        alignItems='center'
        sx={{ '&:hover': { backgroundColor: theme.palette.action.hover }, transition: 'background-color 0.3s' }}
      >
        {React.createElement(icon, { sx: { fontSize: '42px' } })}
        <Typography flex={1} textAlign='left'>
          {label}
        </Typography>
        {valueLabel ? (
          <Stack>
            <Typography align='center' width={60} color={valueColor} fontSize={32} fontWeight={300} lineHeight={1}>
              {value}
            </Typography>
            <Typography align='center' sx={{ fontSize: 12 }}>
              {valueLabel}
            </Typography>
          </Stack>
        ) : (
          <Typography align='center' width={60} color={valueColor} fontSize={42} fontWeight={300} lineHeight={1}>
            {value}
          </Typography>
        )}
      </DashboardCard>
    </ButtonBase>
  );
};

interface IntelligenceDashboardStatProps {
  scorable: Scorable | undefined;
  scoreDimension?: ScoreDimension;
  label: string;
}

export const IntelligenceDashboardStat = ({ scorable, scoreDimension, label }: IntelligenceDashboardStatProps) => {
  if (!scoreDimension) {
    scoreDimension = scorable;
  }
  const [intelligenceModalOpen, setIntelligenceModalOpen] = useState(false);

  return (
    <Fragment>
      <IntelligenceModal
        scorable={scorable}
        scoreDimension={scoreDimension}
        open={intelligenceModalOpen}
        onClose={() => setIntelligenceModalOpen(false)}
      />
      <DashboardStat
        icon={WaterDropRoundedIcon}
        label='Intelligence'
        value={scoreDimension?.intelligence || '-'}
        onClick={() => setIntelligenceModalOpen(true)}
      />
    </Fragment>
  );
};

export const MemberParticipationDashboardStat = ({ scoreDimension }: { scoreDimension?: ScoreDimension | Scorable }) => {
  const contributorPercentage = scoreDimension?.intelligenceStats.contributorPercentage;

  return (
    <DashboardStat
      icon={BarChartRoundedIcon}
      label='Member Participation'
      value={contributorPercentage ? `${contributorPercentage}%` : '-'}
      valueLabel='of members'
    />
  );
};

export const MemberCountDashboardStat = ({ scorable }: { scorable: MembershipInterface }) => {
  let path;
  if (['Client', 'Project', 'Team'].includes(scorable.__typename!)) {
    path = 'members';
  }

  return <DashboardStat icon={PeopleRoundedIcon} label='Members' value={scorable?.memberStats.count || '-'} path={path} />;
};

interface PersonalContributionDashboardStatProps {
  scorable: Scorable | undefined;
  scoreDimension?: ScoreDimensionBase;
}

export const PersonalContributionDashboardStat = ({ scorable, scoreDimension }: PersonalContributionDashboardStatProps) => {
  const scoreSubject = scoreDimension || scorable;

  const relativeContribution = scoreSubject?.intelligenceStats.relativeContribution;

  let relativeContributionValue = '-';
  let relativeContributionValueLabel = '';
  let relativeContributionValueColor;
  if (relativeContribution !== undefined) {
    relativeContributionValue = `${Math.abs(relativeContribution)}%`;
    if (relativeContribution === 0) {
      relativeContributionValueLabel = 'above avg.';
    } else if (relativeContribution > 0) {
      relativeContributionValueLabel = 'above avg.';
      relativeContributionValueColor = 'success.main';
    } else if (relativeContribution < 0) {
      relativeContributionValueLabel = 'below average';
      relativeContributionValueColor = 'error.main';
    } else {
      relativeContributionValue = '-';
    }
  }

  return (
    <DashboardStat
      icon={BarChartRoundedIcon}
      label='My Intelligence Contribution'
      value={relativeContributionValue}
      valueLabel={relativeContributionValueLabel}
      valueColor={relativeContributionValueColor}
    />
  );
};

interface PersonalDashboardStatsProps {
  scorable: Scorable;
  scoreDimension?: ScoreDimension;
  intelligenceLabel: string;
  direction?: 'row' | 'column';
}

export const PersonalDashboardStats = ({ scorable, scoreDimension, intelligenceLabel, direction }: PersonalDashboardStatsProps) => {
  return (
    <DashboardStats direction={direction}>
      <IntelligenceDashboardStat scorable={scorable} scoreDimension={scoreDimension} label={intelligenceLabel} />
      <PersonalContributionDashboardStat scorable={scorable} scoreDimension={scoreDimension} />
    </DashboardStats>
  );
};

interface MembershipDashboardStatsProps {
  scorable: Scorable & MembershipInterface;
  scoreDimension?: ScoreDimension;
  intelligenceLabel: string;
  direction?: 'row' | 'column';
}

export const MembershipDashboardStats = ({ scorable, scoreDimension, intelligenceLabel, direction }: MembershipDashboardStatsProps) => {
  if (!scorable) {
    return null;
  }

  const scoreSubject = scoreDimension || scorable;

  return (
    <DashboardStats direction={direction}>
      <IntelligenceDashboardStat scorable={scorable} scoreDimension={scoreDimension} label={intelligenceLabel} />
      <MemberCountDashboardStat scorable={scorable} />
      <MemberParticipationDashboardStat scoreDimension={scoreSubject} />
    </DashboardStats>
  );
};
