import React from 'react';
import { Stack } from '@mui/material';
import DesktopPrimaryNavigation from './DesktopPrimaryNavigation';
import PrimaryDrawerNavigation from './PrimaryDrawerNavigation';
import AIDrawer from '../AI/AIDrawer';
import NavigationViewport from './NavigationViewport';

const DesktopLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <Stack data-component='DesktopLayout' flex={1} height='100%'>
      <DesktopPrimaryNavigation />
      <NavigationViewport>
        <PrimaryDrawerNavigation>{children}</PrimaryDrawerNavigation>
        <AIDrawer />
      </NavigationViewport>
    </Stack>
  );
};

export default DesktopLayout;
