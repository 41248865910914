import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, Button, Card, CardActionArea, Icon, Stack, Typography } from '@mui/material';
import CorporateFareRoundedIcon from '@mui/icons-material/CorporateFareRounded';
import PageStyle from '../UI/PageStyle';
import SurveyMessage from './SurveyMessage';
import { SurveyInterface } from './SurveyInterface';
import Illustration from '../UI/Illustration';
import { CollectIntelligence } from './SurveyActions';
import { useWorkspace } from '../Workspace/useWorkspace';
import { TeamInterface } from '../Team/TeamSchema';
import { ProjectInterface } from '../Project/ProjectInterface';
import { ClientInterface } from '../Client/ClientInterface';
import { WorkspaceInterface } from '../Workspace/WorkspaceInterface';

interface SurveySelectorProps {
  surveys: SurveyInterface[];
  thanks: boolean;
  onSelect: (survey: SurveyInterface) => void;
  onSelectAll: () => void;
}

const SurveySelector = ({ surveys, thanks, onSelect, onSelectAll }: SurveySelectorProps) => {
  const { workspace } = useWorkspace();
  const heading = thanks ? 'Thank You!' : 'Glad you’re here!';
  const subheading = thanks
    ? 'Let’s keep it going. Select from below to contribute some more.'
    : 'We need your intelligence on some things. Select from below and start contributing…';

  return (
    <PageStyle design="illustrated">
      <Stack spacing={2} alignItems="center">
        <SurveyMessage>
          <Stack alignItems="center">
            <Typography variant="h4" sx={{ textAlign: 'center' }}>
              {heading}
            </Typography>
            <Typography variant="h5" sx={{ textAlign: 'center', textWrap: 'balance' }}>
              {subheading}
            </Typography>
          </Stack>

          <SurveyCards>
            {surveys.map((survey: SurveyInterface) => (
              <SurveyCard key={survey.id} survey={survey} onSelect={onSelect} />
            ))}
          </SurveyCards>

          {surveys.length > 1 && (
            <Button
              disabled={false}
              style={{ marginLeft: 'auto', marginRight: 'auto' }}
              variant="contained"
              disableElevation
              color="primary"
              onClick={onSelectAll}
            >
              I'm ready, hit me with everything!
            </Button>
          )}

          {workspace.canCollectIntelligence && <CollectIntelligence />}
        </SurveyMessage>

        <Box className="illustration background">
          <Illustration image="surveys" alt="Background Illustration" />
        </Box>
      </Stack>
      {/*<Stack flex={1} bgcolor={'red'} alignContent='stretch' />*/}
    </PageStyle>
  );
};

export const SurveyCards = ({ children }: { children: React.ReactNode }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
      gap: 2,
    }}
  >
    {children}
  </Box>
);

interface SurveyCardProps {
  survey: SurveyInterface;
  onSelect: (survey: SurveyInterface) => void;
}

const SurveyCard = ({ survey, onSelect }: SurveyCardProps) => {
  const theme = useTheme();
  const { scorable, module, requester } = survey;
  let avatar, name;

  switch (scorable.__typename) {
    case 'Workspace':
      const workspace = scorable as WorkspaceInterface;
      avatar = (
        <Avatar src={workspace.company.imageUrl}>
          <CorporateFareRoundedIcon />
        </Avatar>
      );
      name = `Workspace ${module.name}`;
      break;
    case 'Client':
      const client = scorable as ClientInterface;
      avatar = (
        <Avatar src={client.imageUrl}>
          <CorporateFareRoundedIcon />
        </Avatar>
      );
      name = `${scorable.name} ${module.name}`;
      break;
    case 'Project':
      const project = scorable as ProjectInterface;
      avatar = (
        <Avatar src={project.client.imageUrl}>
          <CorporateFareRoundedIcon />
        </Avatar>
      );
      name = `${project.client.name} - ${scorable.name} ${module.name}`;
      break;
    case 'Team':
      const team = scorable as TeamInterface;
      avatar = (
        <Avatar sx={{ bgcolor: team.icon.color }}>
          <Icon>{team.icon.symbol}</Icon>
        </Avatar>
      );
      name = `${team.name} Team ${module.name}`;
      break;
    case 'WorkspaceUser':
      break;
    default:
      throw new Error('Unrecognized Scorable');
  }

  const requesterName = requester ? `${requester.firstName} ${requester.lastName}` : 'Hyve';

  return (
    <Card>
      <CardActionArea sx={{ height: '100%' }}>
        <Stack
          key={survey.id}
          width="180px"
          height="100%"
          border={`1px solid ${theme.palette.divider}`}
          borderRadius={2}
          onClick={() => onSelect(survey)}
          alignItems="center"
          spacing={2}
          px={4}
          py={4}
          textAlign="center"
        >
          {avatar}
          <Box sx={{ minHeight: '5em', ...textStyle }}>{name}</Box>
          <Typography variant="body2" sx={textStyle}>
            Requested by {requesterName}
          </Typography>
        </Stack>
      </CardActionArea>
    </Card>
  );
};

const textStyle = {
  display: '-webkit-box',
  WebkitLineClamp: 4,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textWrap: 'balance',
};

export default SurveySelector;
