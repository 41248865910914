import { useState } from 'react';
import useActiveFeatures from '../Module/useActiveFeatures';
import { ActionInterface } from '../UI/ActionInterface';
import HexagonRoundedIcon from '@mui/icons-material/HexagonRounded';
import useWorkspace from '../Workspace/useWorkspace';
import { CompanyInterface } from '../Company/CompanyInterface';

export type AddProjectMode = 'auto' | 'client' | 'internal';

export const useAddProject = (mode: AddProjectMode, company?: CompanyInterface) => {
  const { workspace } = useWorkspace();
  const { clientProjectsActive, companyProjectsActive } = useActiveFeatures();
  const [addProjectCompany, setAddProjectCompany] = useState(company);
  const [addProjectDialogOpen, setAddProjectDialogOpen] = useState(false);

  const addProjectActions: ActionInterface[] = [];

  const isWorkspaceCompany = company?.id === workspace.company.id;

  const showAddClientProjectAction = clientProjectsActive && (mode === 'client' || mode === 'auto') && (!company || !isWorkspaceCompany);
  const showAddInternalProjectAction = companyProjectsActive && (mode === 'internal' || mode === 'auto') && (!company || isWorkspaceCompany);

  if (showAddClientProjectAction) {
    addProjectActions.push({
      id: 'add-client-project',
      label: 'Add Client Project',
      icon: HexagonRoundedIcon,
      onSelect: () => {
        setAddProjectCompany(company || undefined); // Pass undefined when no company is specified
        setAddProjectDialogOpen(true);
      },
    });
  }

  if (showAddInternalProjectAction) {
    addProjectActions.push({
      id: 'add-internal-project',
      label: 'Add Internal Project',
      icon: HexagonRoundedIcon,
      onSelect: () => {
        setAddProjectCompany(workspace.company);
        setAddProjectDialogOpen(true);
      },
    });
  }

  return { addProjectActions, addProjectDialogOpen, addProjectCompany, setAddProjectDialogOpen };
};