import React from 'react';
import { Outlet, useParams } from 'react-router';
import { gql, useQuery } from '@apollo/client';
import AdminPanelSettingsRoundedIcon from '@mui/icons-material/AdminPanelSettingsRounded';

import MembershipNotice from '../Membership/MembershipNotice';
import ProjectSecondaryNavigation from '../Project/ProjectSecondaryNavigation';
import PageStyle from '../UI/PageStyle';
import { useProjectActions } from './ProjectActions';
import { SCORABLE_SURVEY_FIELDS_FRAGMENT } from '../Survey/useSurvey';
import ApolloErrorPage from '../UI/ApolloErrorPage';
import ArchivedNotice from '../Archive/ArchivedNotice';
import { ProjectProvider, useProjectContext } from './ProjectContext';
import { PROJECT_FIELDS_FRAGMENT } from './ProjectInterface';
import PageNotice, { PageNotices } from '../UI/PageNotice';

const Project = () => {
  const { projectId: id } = useParams<{ projectId: string }>();
  const { data, loading, error, refetch } = useQuery(PROJECT_QUERY, { variables: { id: id } });
  if (error) {
    return <ApolloErrorPage error={error} type='Project' />;
  }

  if (!data && loading) {
    return <PageStyle design='standard' loading={true} />;
  }

  const { project } = data;

  const membershipNotice = project.member ? null : <ProjectMembershipNotice />;

  return (
    <ProjectProvider project={project} refetch={refetch}>
      <ProjectSecondaryNavigation />
      <PageNotices>
        <ArchivedNotice archivable={project} />
        {membershipNotice}
        <ProjectConfidentialityThresholdNotice />
      </PageNotices>

      <Outlet />
    </ProjectProvider>
  );
};

const ProjectMembershipNotice = () => {
  const { project, refetch } = useProjectContext();
  const { joinProject } = useProjectActions(project, refetch);
  return <MembershipNotice memberCollector={project} onJoin={joinProject} />;
};

const ProjectConfidentialityThresholdNotice = () => {
  const { project } = useProjectContext();
  const message = `The confidentiality threshold for this project hasn't been met.${project.member ? ` You'll only see scores based on your personal contributions.` : ``}`;

  return (
    <PageNotice
      noticeKey={`project-confidentiality-${project.id}`}
      applicable={!project.confidentialityThresholdMet}
      icon={AdminPanelSettingsRoundedIcon}
      message={message}
    />
  );
};

export default Project;

const PROJECT_QUERY = gql`
  query Project($id: ID!) {
    project(id: $id) {
      ...ProjectFields

      health
      intelligence
      ...ScorableSurveyFields

      memberStats {
        count
      }
      member
      owners: users(withRole: Owner) {
        id
      }
      users {
        id
        firstName
        lastName
        email
        avatar
        pendingInvitation
      }
      client {
        id
        name
        imageUrl
        internal
      }
    }
  }

  ${PROJECT_FIELDS_FRAGMENT}
  ${SCORABLE_SURVEY_FIELDS_FRAGMENT}
`;
