import React from 'react';
import { Link as RouterLink } from 'react-router';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import { Box, CardActionArea, CardActionAreaProps, Stack, StackProps } from '@mui/material';

import { DashboardCard } from '../Dashboard/DashboardCard';
import { Category } from './Category';
import ScoreCircle from '../Scores/ScoreCircle';
import { computeScoreLevel } from '../Scores/Score';
import { ModuleInterface } from '../Module/Module';
import { findModuleScoreDimensionSettings } from '../Module/ModuleDimensionSettings';

import Button from '@mui/material/Button';
import { useWorkspace } from '../Workspace/useWorkspace';
import { ActionInterface } from '../UI/ActionInterface';
import { useLayout } from '../UI/LayoutContext';
import { findCategoryScoreDimensionSettings } from './CategoryScoreDimensionSettings';

interface CategoryDashboardModulesProps {
  category: Category;
  parentPath?: string;
}

const CategoryModuleBreakdown = ({ category, parentPath }: CategoryDashboardModulesProps) => {
  const { modules } = category;

  if (modules.length === 1) {
    return (
      <Stack direction='row' spacing={2}>
        <ModuleSummary category={category} module={modules[0]} parentPath={parentPath} onlyModule />
      </Stack>
    );
  } else {
    return (
      <Stack direction='row' spacing={{ xs: 1, md: 2 }} alignItems='stretch'>
        {category.modules.map((module, index) => (
          <ModuleSummary key={index} category={category} parentPath={parentPath} module={module} />
        ))}
      </Stack>
    );
  }
};

export default CategoryModuleBreakdown;

interface ModuleSummaryProps {
  category: Category;
  module: ModuleInterface;
  parentPath?: string;
  onlyModule?: Boolean;
}

const ModuleSummary = ({ category, module, parentPath, onlyModule = false }: ModuleSummaryProps) => {
  const { icon } = findModuleScoreDimensionSettings(module.type);
  const health = module.health; //  || Math.floor(Math.random() * 100);
  const healthLevel = computeScoreLevel(health);
  const theme = useTheme();
  const { workspace } = useWorkspace();

  if (parentPath === undefined) {
    const { path: categoryPath } = findCategoryScoreDimensionSettings(category.type);
    parentPath = `/workspace/${workspace.id}/${categoryPath}`;
  }

  const path = `${parentPath}/modules/${module.id}`;

  const CardComponent: React.ComponentType<StackProps> | React.ComponentType<CardActionAreaProps> = onlyModule ? Stack : CardActionArea;
  const cardComponentProps = onlyModule ? {} : { component: RouterLink, to: path };

  const themeOverrides = {
    components: {
      MuiCardActionArea: {
        styleOverrides: {
          root: {
            '.MuiCardActionArea-focusHighlight': { backgroundColor: '#000' },
            '&:hover': {
              '.MuiCardActionArea-focusHighlight': {
                opacity: 0.075,
              },
            },
            //          color: '#fff',
          },
        },
      },
    },
  };

  return (
    <ThemeProvider theme={createTheme(theme, themeOverrides)}>
      <DashboardCard
        key={module.type}
        spacing={1}
        p={0}
        flexDirection='column'
        elevation={0}
        bgcolor={theme.palette.mode === 'dark' ? 'rgb(27, 27, 27)' : '#fff'}
        alignItems='stretch'
        justifyContent='stretch'
      >
        <CardComponent sx={{ padding: 2, height: '100%' }} {...cardComponentProps}>
          <Stack alignItems='center' spacing={2} justifyContent='stretch' height='100%'>
            <Stack alignItems='center' flex={1}>
              <div>{React.createElement(icon)}</div>
              <Box fontSize={{ xs: '10px', sm: '12px' }} textAlign='center'>
                {module.name}
              </Box>
            </Stack>
            {onlyModule ? (
              <ModuleActions module={module} path={path} />
            ) : (
              <ScoreCircle score={health} color={healthLevel.color} scoreFontSize='14px' width='40px' colorScoreLabel showProgress={false} />
            )}
          </Stack>
        </CardComponent>
      </DashboardCard>
    </ThemeProvider>
  );
};

const ModuleActions = ({ module, path }: { module: ModuleInterface; path: string }) => {
  const layout = useLayout();
  const { workspace } = useWorkspace();
  const actions: ActionInterface[] = [{ id: 'view-dashboard', label: 'View Dashboard', path: path }];
  switch (module.type) {
    case 'ClientsProjectsModule':
      actions.push({
        id: 'view-projects',
        label: 'View Projects',
        path: `/workspace/${workspace.id}/clients/projects`,
      });
      break;
    case 'CompanyProjectsModule':
      actions.push({
        id: 'view-projects',
        label: 'View Projects',
        path: `/workspace/${workspace.id}/company/projects`,
      });
      break;
  }

  return (
    <Stack direction={layout === 'mobile' ? 'column' : 'row'} spacing={1}>
      {actions.map(action => (
        <Button key={action.id} variant='outlined' component={RouterLink} to={action.path!}>
          {action.label}
        </Button>
      ))}
    </Stack>
  );
};
