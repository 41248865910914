import { useState } from 'react';
import { Link } from 'react-router';
import { Avatar, Button, Chip, Divider, Stack, SvgIcon, TextField } from '@mui/material';
import { ReactComponent as WorkspaceIconSvg } from '../Icons/Bee-Hive-Icon.svg';

import AppSurface from '../UI/AppSurface';
import { SectionHeader, SectionHeaderIcon, SectionHeaderTitle, SectionHeaderActions } from '../UI/Section';
import EditWorkspaceDialog from './EditWorkspaceDialog';
import { useWorkspace } from '../Workspace/useWorkspace';
import CorporateFareRoundedIcon from '@mui/icons-material/CorporateFareRounded';

const WorkspaceIcon = () => {
  return (
    <SvgIcon>
      <WorkspaceIconSvg />
    </SvgIcon>
  );
};

interface AccountWorkspaceSettingsProps {
  refetch: () => Promise<any>;
}

const AccountWorkspaceSettings = ({ refetch }: AccountWorkspaceSettingsProps) => {
  const [open, setOpen] = useState(false);
  const { workspace } = useWorkspace();

  return (
    <AppSurface>
      <SectionHeader>
        <SectionHeaderIcon>
          <WorkspaceIcon />
        </SectionHeaderIcon>
        <SectionHeaderTitle>
          Workspace
          <Chip label="Free Plan" />
        </SectionHeaderTitle>
        <SectionHeaderActions>
          <Link to={`/workspace/${workspace.id}/edit`}></Link>
          <Button onClick={() => setOpen(true)}>Manage Workspace</Button>
          <EditWorkspaceDialog open={open} onClose={() => setOpen(false)} refetch={refetch} />
        </SectionHeaderActions>
      </SectionHeader>

      <Stack px={2} py={4}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Avatar src={workspace.company.imageUrl}>
            <CorporateFareRoundedIcon />
          </Avatar>
          <TextField variant="outlined" disabled label="Workspace Name" value={workspace.name}
                     sx={{ flex: 1, maxWidth: '600px' }} />
        </Stack>
      </Stack>
      <Divider />
    </AppSurface>
  );
};

export default AccountWorkspaceSettings;
