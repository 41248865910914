import React from 'react';
import { useOutletContext, useParams } from 'react-router';
import { useClientModuleScores } from '../Module/ModuleScores';
import { findModuleScoreDimensionSettings } from '../Module/ModuleDimensionSettings';
import { Category } from '../Category/Category';
import { ClientInterface } from './ClientInterface';

interface ClientDashboardModuleProps {
  client: ClientInterface;
  category: Category;
  scopePrefix?: string;
  children: React.ReactNode;
}

const ClientDashboardModule = () => {
  const { client, category, scopePrefix } = useOutletContext<ClientDashboardModuleProps>();
  const { moduleId } = useParams<{ moduleId: string }>();
  const module = category.modules.find(module => module.id === moduleId)!;
  const { DashboardComponent } = findModuleScoreDimensionSettings(module.type);

  const { moduleScores } = useClientModuleScores(category.type, module.type);
  if (!moduleScores) {
    return null;
  }

  return React.createElement(DashboardComponent, { scorable: client, category, module: moduleScores, scopePrefix });
};

export default ClientDashboardModule;
