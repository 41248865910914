import React, { Fragment, useState } from 'react';
import { Link as RouterLink } from 'react-router';
import { gql } from '@apollo/client';

import { Avatar, Divider, Icon, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import EmojiFoodBeverageRoundedIcon from '@mui/icons-material/EmojiFoodBeverageRounded';
import CoffeeRoundedIcon from '@mui/icons-material/CoffeeRounded';

import { DashboardCard } from './DashboardCard';
import ClientAvatar from '../Client/ClientAvatar';
import { aspectIcon } from '../Aspect/AspectIcon';
import EntityScoreIcon from '../Scores/EntityScoreIcon';
import { ProjectInterface } from '../Project/ProjectInterface';
import { Aspect } from '../Aspect/Aspect';
import { Category } from '../Category/Category';
import { findScoreDimensionSettings } from '../Scores/ScoreDimensionSettings';
import { CategoryScoreDimensionSettings } from '../Category/CategoryScoreDimensionSettings';
import { useWorkspace } from '../Workspace/useWorkspace';
import { ScoreDimension } from '../Scores/ScoreDimension';

interface NeedsAttentionProps {
  scorables: ScoreDimension[];
  category: Category;
}

const NeedsAttention = ({ scorables, category }: NeedsAttentionProps) => {
  const [randomValue] = useState(() => Math.random());

  if (scorables.length === 0) {
    return (
      <DashboardCard title='Needs Attention'>
        <Stack flex={1} alignItems='center' justifyContent='center' gap={1}>
          {randomValue > 0.5 ? <CoffeeRoundedIcon fontSize='large' color='neutral' /> : <EmojiFoodBeverageRoundedIcon fontSize='large' color='neutral' />}
          <Typography variant='body2' color='neutral'>
            Nothing needs attention at this time
          </Typography>
        </Stack>
      </DashboardCard>
    );
  }

  return (
    <DashboardCard flex={1} px={0} title='Needs Attention'>
      <List>
        {scorables.map(scorable => {
          return (
            <Fragment key={`${scorable.__typename}-${scorable.id}`}>
              <Divider />
              <NeedsAttentionItem scoreDimension={scorable} category={category} />
            </Fragment>
          );
        })}
      </List>
    </DashboardCard>
  );
};

const NeedsAttentionItem = ({ scoreDimension, category }: { scoreDimension: ScoreDimension; category: Category }) => {
  const { workspace } = useWorkspace();
  const primary = scoreDimension.name;
  let icon, secondary, path;
  let member = true;

  switch (scoreDimension.__typename) {
    case 'Client':
      const client = scoreDimension;
      icon = <ClientAvatar client={client} />;
      member = client.projectMember!;
      path = `/workspace/${workspace.id}/clients/${client.id}`;
      break;
    case 'Project':
      const project = scoreDimension as ProjectInterface;
      icon = <ClientAvatar client={project.client!} />;
      secondary = project.client!.name;
      member = project.member!;
      path = `/workspace/${workspace.id}/projects/${project.id}`;
      break;
    case 'Aspect':
      const { id, name, module } = scoreDimension as Aspect;
      const categoryComponentSettings = findScoreDimensionSettings(category) as CategoryScoreDimensionSettings;
      icon = (
        <Avatar>
          <Icon>{aspectIcon(module!.type, name)}</Icon>
        </Avatar>
      );
      secondary = module!.name;
      path = `/workspace/${workspace.id}/${categoryComponentSettings.path}/modules/${module.id}/aspects/${id}`;
      break;
    default:
      throw new Error(`Unexpected scorable type: ${scoreDimension.type}`);
  }

  return (
    <ListItem sx={{ padding: 0 }} key={scoreDimension.id} secondaryAction={<EntityScoreIcon score={scoreDimension.health} filled={member} />}>
      <ListItemButton component={RouterLink} to={path} sx={{ paddingRight: 2 }}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={primary} secondary={secondary} sx={{ fontSize: 14 }} />
      </ListItemButton>
    </ListItem>
  );
};

export default NeedsAttention;

export const NEEDS_ATTENTION_FRAGMENT = gql`
  fragment NeedsAttention on CategoryInterface {
    needsAttention {
      key @client
      id
      name
      health
      ... on Client {
        id
        imageUrl
        projectMember
      }
      ... on Project {
        member
        client {
          id
          name
          imageUrl
        }
      }
      ... on Aspect {
        module {
          id
          name
          type
        }
      }
    }
  }
`;
