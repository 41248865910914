import { Autocomplete, InputAdornment, ListItem, ListItemIcon, ListItemText, TextField } from '@mui/material';
import LockIconRounded from '@mui/icons-material/LockRounded';
import TagIconRounded from '@mui/icons-material/TagRounded';
import { Controller, useFormContext } from 'react-hook-form';
import { Channel } from './Channel';

type ChannelSelectProps = {
  options: Channel[];
};

const ChannelSelect = ({ options }: ChannelSelectProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name='existingChannel'
      control={control}
      render={({ field }) => (
        <Autocomplete
          {...field}
          options={options}
          renderInput={params => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                startAdornment: <InputAdornment position='start'>#</InputAdornment>,
              }}
              variant='outlined'
              fullWidth
              label='Slack Channel'
            />
          )}
          renderOption={(props, option) => (
            <ListItem {...props} key={option.id}>
              <ListItemIcon>{option.isPrivate ? <LockIconRounded /> : <TagIconRounded />}</ListItemIcon>
              <ListItemText primary={option.name} />
            </ListItem>
          )}
          getOptionLabel={option => option.name}
          onChange={(_event, value) => {
            field.onChange({ id: value.id, name: value.name });
          }}
          value={field.value}
        />
      )}
    />
  );
};

export default ChannelSelect;
