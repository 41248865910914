import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router';

import { Badge, BottomNavigation, BottomNavigationAction, Box } from '@mui/material';

import { PrimaryNavigationArea, usePrimaryNavigation } from './usePrimaryNavigation';
import { NAVIGATION_THEME } from '../UI/NavigationTheme';
import { useWorkspace } from '../Workspace/useWorkspace';
import useAuthenticatedUser from '../User/useAuthenticatedUser';
import { useAI } from '../AI/AIContext';

const MobilePrimaryNavigation = () => {
  const navigate = useNavigate();
  const { user } = useAuthenticatedUser();
  const { workspace } = useWorkspace();
  const { areas, activeArea } = usePrimaryNavigation();
  const { open: aiOpen, setOpen: setAIOpen } = useAI();

  const bottomNavigationActiveArea = aiOpen ? areas.ai : activeArea;

  if (!user || !workspace) {
    return null;
  }

  const areaList: PrimaryNavigationArea[] = [
    areas.workspace,
    {
      ...areas.intelligence,
      badge: workspace.needsMyIntelligence,
    },
    areas.ai,
  ].filter(area => area);

  const handleNavigation = (area: PrimaryNavigationArea) => {
    if (area.path) {
      navigate(area.path);
    }
    if (area.key === 'ai') {
      setAIOpen(true);
    } else {
      setAIOpen(false);
    }
  };

  return (
    <ThemeProvider theme={NAVIGATION_THEME}>
      <BottomNavigation
        value={bottomNavigationActiveArea}
        showLabels={true}
        sx={{
          zIndex: 1400,
          order: 1,
          position: 'fixed',
          bottom: 0,
          height: '64px',
          width: '100%',
          color: theme => theme.palette.text.primary,
          borderTop: '1px solid rgba(255, 255, 255, .12)',
        }}
      >
        {areaList.map(area => (
          <BottomNavigationAction
            key={area.name}
            icon={<NavigationIcon area={area} />}
            label={area.label}
            value={area}
            onClick={() => handleNavigation(area)}
            sx={{
              minWidth: 'inherit',
              '.MuiBottomNavigationAction-label': {
                fontSize: '11px',
                '&.Mui-selected': {
                  fontSize: '11px',
                  color: theme => theme.palette.secondary.main,
                },
              },
            }}
          />
        ))}
      </BottomNavigation>
    </ThemeProvider>
  );
};

const NavigationIcon = ({ area }: { area: PrimaryNavigationArea }) => {
  const icon = (
    <Box
      sx={{
        '.navigation-icon': {
          width: '24px',
          height: '24px',
          marginBottom: '2px',
          fontSize: '24px',
          color: theme => theme.palette.text.primary,
          fill: theme => theme.palette.text.primary,
          g: {
            fill: theme => theme.palette.text.primary,
          },
          '.Mui-selected &': {
            color: theme => theme.palette.secondary.main,
            fill: theme => theme.palette.secondary.main,
            g: {
              fill: theme => theme.palette.secondary.main,
            },
          },
        },
      }}
    >
      {area.icon}
    </Box>
  );

  return area.badge ? (
    <Badge
      overlap='rectangular'
      variant='dot'
      color='secondary'
      sx={{
        '.MuiBadge-dot': {
          backgroundColor: theme => theme.palette.warning.main,
          top: '4px',
          right: '-6px',
        },
      }}
    >
      {icon}
    </Badge>
  ) : (
    icon
  );
};

export default MobilePrimaryNavigation;
