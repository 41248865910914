import { z } from 'zod';
import { WorkspaceInterface } from '../Workspace/WorkspaceInterface';
import { Role } from './Roles';
import { TeamInterface } from '../Team/TeamSchema';
import { ScorableBase } from '../Scores/ScorableBase';
import { gql } from '@apollo/client';

export const TOKEN = 'token';
export const MINIMUM_PASSWORD_LENGTH = 6;

export type WorkplaceType = 'Remote' | 'Hybrid' | 'Office';
export type ThemeType = 'Dark' | 'Light' | 'System';

export type WorkspaceUser = ScorableBase & {
  __typename: 'WorkspaceUser';
  type: 'WorkspaceUser';
};

export interface UserInterface {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  avatar?: string;
  pendingInvitation: boolean;
  parentRoles: Role[];
  officeType: WorkplaceType;
  timeZone: string;
  theme: ThemeType;
  workspaces: WorkspaceInterface[];
  effectiveRoles: Role[];
  team: TeamInterface;
  slackMessagingDisabled: boolean;
  hasSlackUserAccess: boolean;
}

export const userSchema = z.object({
  id: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email(),
  avatar: z.string().url().nullable().optional(),
  pendingInvitation: z.boolean().optional(),
  team: z.object({ id: z.string(), name: z.string() }).nullable().optional(),
});

export type UserType = z.infer<typeof userSchema>;

export const userInviteEmailSchema = z.object({
  email: z.string().email(),
});
export type UserInviteEmail = z.infer<typeof userInviteEmailSchema>;

export const userSelectOrInviteEmailSchema = z.union([userSchema, userInviteEmailSchema]);

export const formatUser = (user: UserInterface) => {
  let label;
  if (user.id) {
    if (!user.pendingInvitation) {
      label = `${user.firstName} ${user.lastName} - ${user.email}`;
    } else {
      label = `${user.email} (Invite Pending)`;
    }
  } else {
    label = user.email;
  }
  return label;
};

export const USER_FIELDS = `
  id
  email
  firstName
  lastName
  avatar
  theme
  workspaces {
    id
    name
  }
  effectiveRoles
`;

export const USER_QUERY = gql`
  query User($id: ID!) {
    user(id: $id) {
      ${USER_FIELDS}
    }
  }
`;
