import { Fragment } from 'react';
import { Link as RouterLink } from 'react-router';

import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { Button, IconButton } from '@mui/material';

import ClientActions from '../Client/ClientActions';
import ClientAvatar from '../Client/ClientAvatar';
import SecondaryNavigation, { SecondaryNavigationActions, SecondaryNavigationHeading } from '../Navigation/SecondaryNavigation';
import ScopeSelector from '../Scope/ScopeSelector';
import { ChevronRightRounded } from '@mui/icons-material';
import { SecondaryNavigationTabs, useSecondaryNavigationTabs } from '../Navigation/SecondaryNavigationTabs';
import useActiveFeatures from '../Module/useActiveFeatures';
import { useScope } from '../Scope/useScope';
import { useWorkspace } from '../Workspace/useWorkspace';
import { useClientContext } from './ClientContext';
import { useClients } from '../Clients/ClientsContext';
import ChannelConnectionAction from '../Chat/ChannelConnectionAction';
import { generateChannelName } from '../Chat/Channel';

type ClientTab = {
  key: string;
  label: string;
  path: string;
  internal?: boolean;
};

const useClientTabs = () => {
  const { clientProjectsActive } = useActiveFeatures();

  const dashboardTab = { key: 'dashboard', label: 'Dashboard', path: '' };
  const projectsTab = { key: 'projects', label: 'Projects', path: 'projects' };
  const aspectsTab = { key: 'aspects', label: 'Aspects', path: 'aspects' };
  const membersTab = { key: 'members', label: 'Members', path: 'members', internal: false };

  const tabs: ClientTab[] = [dashboardTab];
  if (clientProjectsActive) {
    tabs.push(projectsTab);
  }
  tabs.push(aspectsTab);
  tabs.push(membersTab);

  return tabs;
};

const ClientSecondaryNavigation = () => {
  const { client, clientPath } = useClientContext();
  const { workspace } = useWorkspace();
  const { id, name } = client;
  const tabs = useClientTabs();
  const { clientsPath } = useClients();

  const secondaryNavigationTabsProps = useSecondaryNavigationTabs(tabs, clientPath);

  return (
    <Fragment>
      <SecondaryNavigation>
        <SecondaryNavigationHeading>
          <IconButton component={RouterLink} to={clientsPath}>
            <ArrowBackRoundedIcon />
          </IconButton>
          <Button
            component={RouterLink}
            to={`/workspace/${workspace.id}/clients/${id}`}
            startIcon={<ClientAvatar client={client} />}
            sx={{ textTransform: 'none' }}
          >
            {name}
          </Button>
          <ChevronRightRounded />
          <ClientScopeMenu />
        </SecondaryNavigationHeading>
        <SecondaryNavigationActions>
          <SecondaryNavigationTabs {...secondaryNavigationTabsProps} />
          <ChannelConnectionAction scorable={client} defaultChannelName={generateChannelName(client.name, 'client')} />
          {/*<ChannelConnectionAction channel={null} defaultChannel={generateChannelSlug(client.name, 'client')} />*/}
          <ClientActions />
        </SecondaryNavigationActions>
      </SecondaryNavigation>
    </Fragment>
  );
};

export const ClientScopeMenu = () => {
  // const workspaceScope = { ...UNSCOPED_SCOPE, prefix: 'Workspace ' };
  const { scopes } = useScope();
  const scopeOptions = scopes.map(scope => {
    //    const count = scope.key === availableScope.key ? clients.length : undefined;
    return { label: `${scope.selectorPrefix} Scores`, scope: scope, count: undefined };
  });

  return <ScopeSelector options={scopeOptions} includeUnscopedForAllMembers />;
};

export default ClientSecondaryNavigation;
