import { ClientsProvider } from '../Clients/ClientsContext';
import { Outlet } from 'react-router';
import useWorkspace from '../Workspace/useWorkspace';

const ClientsArea = () => {
  const { workspace } = useWorkspace();
  return (
    <ClientsProvider>
      <Outlet context={{ scorable: workspace }} />
    </ClientsProvider>
  );
};

export default ClientsArea;
