import React from 'react';
import { useOutletContext, useParams } from 'react-router';

import { useWorkspaceModuleScores } from '../Module/ModuleScores';
import { findModuleScoreDimensionSettings } from '../Module/ModuleDimensionSettings';
import { Category } from './Category';
import { Scorable } from '../Scores/Scorable';

interface CategoryDashboardModuleProps {
  scorable: Scorable;
  category: Category;
  scopePrefix?: string;
  children: React.ReactNode;
}

const CategoryDashboardModule = () => {
  const { scorable, category, scopePrefix } = useOutletContext<CategoryDashboardModuleProps>();
  const { moduleId } = useParams<{ moduleId: string }>();
  const module = category.modules.find(module => module.id === moduleId)!;
  const { DashboardComponent } = findModuleScoreDimensionSettings(module.type);

  const { moduleScores } = useWorkspaceModuleScores(category.type, module.type);
  if (!moduleScores) {
    return null;
  }

  return React.createElement(DashboardComponent, { scorable, category, module: moduleScores, scopePrefix });
};

export default CategoryDashboardModule;
