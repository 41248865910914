import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Autocomplete, AutocompleteRenderInputParams, Avatar, Chip, TextField } from '@mui/material';

import { UserInviteEmail, UserType } from '../User/User';
import { useWorkspace } from '../Workspace/useWorkspace';

type WorkspaceUsersData = {
  workspace: {
    users: UserType[];
  };
};

export const useMemberSelect = () => {
  const { workspace } = useWorkspace();
  const {
    data,
    loading,
    error,
  } = useQuery<WorkspaceUsersData>(WORKSPACE_USERS_QUERY, { variables: { workspaceId: workspace.id } });
  const workspaceUsers = data?.workspace.users || [];
  return { workspaceUsers, loading, error, formatOption };
};

type MemberSelectProps = {
  name: string;
  value: (UserType | UserInviteEmail)[];
  loading: boolean;
  options: UserType[];
  disabledOptions?: UserType[];
  onChange: (members: (UserType | UserInviteEmail)[]) => void;
  allowInvites?: boolean;
  autoFocus?: boolean;
  error?: { message?: string };
};

const MemberSelect = React.forwardRef(
  ({
     name,
     options,
     disabledOptions = [],
     value = [],
     loading,
     onChange,
     allowInvites = true,
     autoFocus = false,
     error,
   }: MemberSelectProps, ref) => {
    const autoCompleteValue = value.map(entry => {
      if ('id' in entry) {
        return entry;
      } else if ('email' in entry) {
        return entry.email;
      } else {
        return entry;
      }
    });

    const handleChange = (_: React.SyntheticEvent, value: (UserType | string)[]) => {
      const members = value.map(option => {
        // Check if the option is a string (new email) or an existing user
        return typeof option === 'string' ? { email: option } : option;
      });
      // console.log(members);
      onChange(members);
    };

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
      const inputValue = event.target.value;
      if (inputValue) {
        const newMember = { email: inputValue };
        onChange([...value, newMember]); // Append the new email to the current value
      }
    };

    const getOptionDisabled = (option: UserType) => {
      return disabledOptions.map(disabledOption => disabledOption.id).includes(option.id);
    };

    const label = allowInvites ? 'Add members by name or e⁣mail' : 'Add members'; // using an invisible comma between 'e' and 'mail' to prevent password managers from trying to auto-complete

    return (
      <Autocomplete
        data-cy={name}
        onChange={handleChange}
        multiple
        disabled={loading}
        options={options}
        value={autoCompleteValue}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionDisabled={getOptionDisabled}
        getOptionLabel={option => formatOption(option)}
        freeSolo
        onBlur={handleBlur}
        clearOnBlur
        autoHighlight
        filterSelectedOptions={true}
        openOnFocus={false}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            {...params}
            name={name}
            inputRef={ref}
            variant="outlined"
            fullWidth
            label={label}
            autoFocus={autoFocus}
            error={!!error}
            helperText={error?.message?.toString()}
          />
        )}
        renderTags={(value, getTagProps) => {
          return value.map((option: UserType, index) => {
            const { key, ...tagProps } = getTagProps({ index });
            const avatar = option.id ? option.avatar : undefined;
            return <Chip key={key} avatar={<Avatar src={avatar || undefined} />} color="secondary"
                         label={formatOption(option)} {...tagProps} />;
          });
        }}
      />
    );
  },
);

export default MemberSelect;

const formatOption = (option: UserType | string) => {
  // Case 1: Option is a string (Email)
  if (typeof option === 'string') {
    return option;
  }

  // Case 2: User Object with Complete Information
  if (option.id && option.firstName && option.lastName) {
    return option.pendingInvitation ? `${option.email} (Invite Pending)` : `${option.firstName} ${option.lastName} - ${option.email}`;
  }

  // Case 3: User Object with Pending Invitation
  if (option.pendingInvitation) {
    return `${option.email} (Invite Pending)`;
  }

  // Case 4: User Object with Only Email
  if (option.email) {
    return option.email;
  }

  // Case 5: Other Unforeseen Cases
  return 'Unknown Member';
};

const WORKSPACE_USERS_QUERY = gql`
  query WorkspaceUsers($workspaceId: ID!) {
    workspace(id: $workspaceId) {
      id
      users {
        id
        firstName
        lastName
        email
        avatar
        pendingInvitation
        team {
          id
          name
        }
      }
    }
  }
`;
