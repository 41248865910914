import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router';
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';
import ScoreCircle, { ScoreCircleIntelligence } from '../Scores/ScoreCircle';
import { DashboardCard } from './DashboardCard';
import CategoryModuleBreakdown from '../Category/CategoryModuleBreakdown';
import { Category } from '../Category/Category';
import { computeScoreLevel } from '../Scores/Score';
import NeedsAttention from './NeedsAttention';
import { useLayout } from '../UI/LayoutContext';
import { ScoreModal } from '../Scores/ScoreModal';
import { findScoreDimensionSettings } from '../Scores/ScoreDimensionSettings';
import { Box, Button, Divider } from '@mui/material';
import { ActionInterface } from '../UI/ActionInterface';
import { Scorable } from '../Scores/Scorable';

interface DashboardCategoryDetailsProps {
  scorable: Scorable;
  category: Category;
  title?: string;
  includeScoreCircle?: boolean;
  children: React.ReactNode;
  actions?: ActionInterface[];
  alignActions?: 'center' | 'start';
  notice?: React.ReactNode;
}

// TODO: break this apart into 1) the layout including 'needs attention' and 2) the category-specific detail
const DashboardCategoryDetails = ({
  scorable,
  category,
  title,
  includeScoreCircle = true,
  children,
  actions = [],
  alignActions = 'start',
  notice,
}: DashboardCategoryDetailsProps) => {
  const layout = useLayout();

  return (
    <Stack spacing={2}>
      <Box>
        {/* The Box is needed to prevent the Stack from adjusting the Grid margins */}
        <Grid container spacing={{ xs: 2, md: 4 }} bgcolor='red1'>
          <Grid className='score-circle-and-details' xs={12} md={12} lg={includeScoreCircle ? 8 : 6} bgcolor='darkred1' display='flex' alignItems='stretch'>
            <ScoreCircleAndDetails
              scorable={scorable}
              category={category}
              title={title}
              includeScoreCircle={includeScoreCircle}
              children={children}
              actions={actions}
              alignActions={alignActions}
            />
          </Grid>

          {layout !== 'mobile' && (
            <Grid sm={12} lg={includeScoreCircle ? 4 : 6} display='flex' bgcolor='cyan1'>
              <NeedsAttention scorables={category.needsAttention} category={category} />
            </Grid>
          )}
        </Grid>
      </Box>

      {notice && (
        <Box>
          {/* The Box is needed to prevent the Stack from adjusting the Grid margins */}
          <Grid xs={12} display='flex' justifyContent='center'>
            {notice}
          </Grid>
        </Box>
      )}
    </Stack>
  );
};

interface ScoreCircleAndDetailsProps {
  scorable: Scorable;
  category: Category;
  title?: string;
  includeScoreCircle: boolean;
  children: React.ReactNode;
  actions?: ActionInterface[];
  alignActions?: 'center' | 'start';
}

const ScoreCircleAndDetails = ({ scorable, category, title, includeScoreCircle, children, alignActions, actions = [] }: ScoreCircleAndDetailsProps) => {
  const layout = useLayout();
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!open);

  const scoreFontSize = layout === 'mobile' ? '48px' : '60px';
  const scoreCircleWidth = layout === 'mobile' ? '100px' : '140px';
  const showIntelligence = layout !== 'mobile';

  const componentSettings = findScoreDimensionSettings(category);
  const { health } = category;
  const healthLevel = computeScoreLevel(health);

  const actionButtons = (
    <Stack alignItems={alignActions} spacing={1}>
      {actions.map(action => (
        <Button key={action.label} variant='outlined' component={RouterLink} to={action.path!}>
          {action.label}
        </Button>
      ))}
    </Stack>
  );

  return (
    <DashboardCard
      title={title}
      flex={1}
      sx={{ flexGrow: 1 }}
      spacing={2}
      divider={<Divider flexItem className='MuiDivider-fullBleed' />}
      alignItems='stretch'
      bgcolor='red1'
    >
      <Stack direction='row' spacing={4} alignItems='center' bgcolor='orange1' p={0}>
        {includeScoreCircle && (
          <>
            <ScoreModal scorable={scorable} icon={componentSettings.icon} label={category.name} scoreDimension={category} open={open} onClose={toggleOpen} />
            <ScoreCircle width={scoreCircleWidth} score={health} color={healthLevel.color} scoreFontSize={scoreFontSize} onClick={toggleOpen}>
              {showIntelligence && <ScoreCircleIntelligence scorable={scorable} scoreDimension={category} label={`${category.name} Intelligence`} />}
            </ScoreCircle>
          </>
        )}
        <Stack spacing={2} bgcolor='purple1' flex={1}>
          {children}
          {layout !== 'mobile' && actionButtons}
        </Stack>
      </Stack>

      <CategoryModuleBreakdown category={category} />
    </DashboardCard>
  );
};

export default DashboardCategoryDetails;
