import React from 'react';
import { Link as RouterLink } from 'react-router';

import { useTheme } from '@mui/material/styles';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// import Section, { SectionHeader, SectionHeaderIcon, SectionHeaderTitle, SectionHeaderActions } from '../UI/Section';
//
// <SectionHeader>
//   <SectionHeaderIcon></SectionHeaderIcon>
//   <SectionHeaderTitle></SectionHeaderTitle>
//   <SectionHeaderActions>
//   </SectionHeaderActions>
// </SectionHeader>

// TODO update to component='section'
const Section = ({ children }: { children: React.ReactNode; }) => {
  return <Stack>{children}</Stack>;
};

interface SectionHeaderProps {
  parentPath?: string;
  children: React.ReactNode;
}

export const SectionHeader = ({ children, parentPath, ...props }: SectionHeaderProps) => {
  const theme = useTheme();
  return (
    <Stack
      className='section-header'
      direction='row'
      alignItems='center'
      p={2}
      minHeight='73px'
      borderBottom='1px solid'
      borderColor={theme.palette.divider}
      {...props}
    >
      {parentPath && (
        <IconButton component={RouterLink} to={parentPath} edge='start'>
          <ArrowBackRoundedIcon />
        </IconButton>
      )}
      {children}
    </Stack>
  );
};

export const SectionHeaderIcon = ({ children }: { children: React.ReactNode; }) => {
  return (
    <Stack className='section-header-icon' alignItems='center' justifyContent='center' mr={1}>
      {children}
    </Stack>
  );
};

export const SectionHeaderTitle = ({ children }: { children: React.ReactNode; }) => {
  return (
    <Typography
      className='section-header-title'
      variant='h2'
      flexGrow={1}
      sx={{
        '& .MuiButton-root': {
          borderRadius: '4px',
          textTransform: 'none',
          fontWeight: 700,
          fontSize: '1em',
        },
      }}
    >
      {children}
    </Typography>
  );
};

export const SectionHeaderBackNavigation = () => {
  return <Box className='section-header-back-navigation' mr={1} />;
};

interface SectionHeaderActionsProps {
  children: React.ReactNode;
}

export const SectionHeaderActions = ({ children }: SectionHeaderActionsProps) => {
  const theme = useTheme();

  return (
    <Stack
      className='section-header-actions'
      direction='row'
      alignItems='center'
      sx={{
        '.MuiButton-root': {
          color: theme.palette.subtle.contrastText,
          textTransform: 'none',
          pl: 1,
          pr: 1,
          '&.highlight': {
            backgroundColor: '#c7efff',
            color: '#333'
          },
        },
      }}
    >
      {children}
    </Stack>
  );
};

export const SectionContent = ({ children }: { children: React.ReactNode; }) => {
  return <Stack p={2}>{children}</Stack>;
};

export default Section;
