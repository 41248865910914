import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router';

import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Link from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import { ElementSize, GridCellParams, GridColDef, GridRenderCellParams, GridRowsProp, GridSortModel } from '@mui/x-data-grid';

import EntityScoreIcon from '../Scores/EntityScoreIcon';
import TeamIcon from '../Team/TeamIcon';
import { useLayout } from '../UI/LayoutContext';
import StyledDataGrid, { DATA_GRID_COLUMN_HEADER_STYLES, getColumnVisibilityModel } from '../UI/StyledDataGrid';
import { TeamInterface } from './TeamSchema';
import useActiveFeatures from '../Module/useActiveFeatures';
import { Category } from '../Category/Category';
import Typography from '@mui/material/Typography';
import { computeScoreLevel } from '../Scores/Score';

type ColumnField =
  | 'name'
  | 'actions'
  | 'score'
  | 'manager'
  | 'clients'
  | 'projects'
  | 'members'
  | 'intelligence'
  | 'clients-score'
  | 'employee-experience-score'
  | 'company-score';

const FIELD_PRIORITY: ColumnField[] = [
  'name',
  'actions',
  'score',
  'members',
  'clients-score',
  'employee-experience-score',
  'company-score',
  'clients',
  'projects',
  'manager',
  'intelligence',
];

const ROWS_PER_PAGE = 50;

interface TeamDataGridProps {
  teams: TeamInterface[];
  loading: boolean;
  actionsComponent: React.FC<{ team: TeamInterface }>;
}

interface TeamRow {
  team: TeamInterface;
  ancestors: TeamInterface[];
}

const TeamDataGrid = ({ teams, loading, actionsComponent }: TeamDataGridProps) => {
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const navigate = useNavigate();
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'score', sort: 'asc' }]);

  const columns = useColumns(actionsComponent);

  const rows: GridRowsProp = teams.map(team => {
    const { id, name, clientStats, projectStats, ancestors, manager, users, health, intelligence } = team;
    const managerName = manager ? `${manager.firstName} ${manager.lastName}` : null;

    return {
      team: team,
      id,
      name,
      ancestors,
      manager: managerName,
      members: users,
      clients: clientStats.count,
      projects: projectStats.count,
      score: health,
      intelligence: intelligence,
    };
  });

  const handleCellClick = (params: GridCellParams) => {
    const field = params.field as ColumnField;
    const { row } = params;
    const { team } = row;

    switch (field) {
      case 'name':
        navigate(`${team.id}`);
        break;
      case 'manager':
      case 'members':
        navigate(`${team.id}/members`);
        break;
      case 'clients':
        navigate(`${team.id}/clients`);
        break;
      case 'projects':
        navigate(`${team.id}/projects`);
        break;
      case 'actions':
        break;
      default:
        navigate(`${team.id}`);
    }
  };

  const handleResize = (containerSize: ElementSize) => {
    setColumnVisibilityModel(getColumnVisibilityModel(columns, FIELD_PRIORITY, containerSize.width));
  };

  return (
    <StyledDataGrid
      loading={loading}
      rowHeight={70}
      rows={rows}
      columns={columns}
      columnVisibilityModel={columnVisibilityModel}
      onResize={handleResize}
      sortingOrder={['asc', 'desc']}
      hideFooter={rows.length <= ROWS_PER_PAGE}
      disableColumnMenu={true}
      disableRowSelectionOnClick={true}
      sortModel={sortModel}
      onSortModelChange={model => {
        if (JSON.stringify(model) !== JSON.stringify(sortModel)) {
          setSortModel(model);
        }
      }}
      onCellClick={handleCellClick}
      sx={{ ...DATA_GRID_COLUMN_HEADER_STYLES }}
    />
  );
};

type TeamGridColDef = GridColDef & {
  field: ColumnField;
};

const useColumns = (actionsComponent: React.FC<{ team: TeamInterface }>): TeamGridColDef[] => {
  const layout = useLayout();
  const { clientsActive, projectsActive, employeeExperienceActive, companyActive } = useActiveFeatures();

  const columns: TeamGridColDef[] = [];

  const nameColumn: TeamGridColDef = {
    field: 'name',
    headerName: 'Team Name',
    minWidth: layout === 'desktop' || layout === 'laptop' ? 240 : 180,
    flex: 1,
    renderCell: (cellValues: GridRenderCellParams<TeamRow>) => {
      const { team, ancestors } = cellValues.row;
      const ancestorText = ancestors.map(ancestor => ancestor.name).join(' > ');

      return (
        <ListItem key={team.id}>
          <ListItemIcon>
            <TeamIcon icon={team.icon} />
          </ListItemIcon>
          <ListItemText
            primary={
              <Link to={`${team.id}`} component={RouterLink} onClick={event => event.stopPropagation()} order={1}>
                {team.name}
              </Link>
            }
            secondary={ancestorText}
          />
          {/*{archived && <Icon style={{ margin: 0 }} className='list-item-status-icon' >archived</Icon>}*/}
        </ListItem>
      );
    },
  };
  columns.push(nameColumn);

  const scoreColumn: TeamGridColDef = {
    field: 'score',
    headerName: 'Team Score',
    width: 100,
    // headerAlign: 'center',
    // align: 'center',
    renderCell: cellValues => {
      const { team } = cellValues.row;
      return <EntityScoreIcon score={team.health} filled={team.member} />;
    },
  };
  columns.push(scoreColumn);

  const clientsScoreColumn: TeamGridColDef = {
    field: 'clients-score',
    headerName: 'Clients Score',
    width: 100,
    renderCell: cellValues => {
      const { team } = cellValues.row;
      const score = team.categories.find((category: Category) => category.type === 'ClientsCategory')?.health;
      const color = computeScoreLevel(score).color;
      return <Typography color={color}>{score || '-'}</Typography>;
    },
  };
  if (clientsActive) {
    columns.push(clientsScoreColumn);
  }

  const employeeExperienceScoreColumn: TeamGridColDef = {
    field: 'employee-experience-score',
    headerName: 'Employee Experience Score',
    width: 100,
    renderCell: cellValues => {
      const { team } = cellValues.row;
      const score = team.categories.find((category: Category) => category.type === 'EmployeeExperienceCategory')?.health;
      const color = computeScoreLevel(score).color;
      return <Typography color={color}>{score || '-'}</Typography>;
    },
  };
  if (employeeExperienceActive) {
    columns.push(employeeExperienceScoreColumn);
  }

  const companyScoreColumn: TeamGridColDef = {
    field: 'company-score',
    headerName: 'Company Score',
    width: 100,
    renderCell: cellValues => {
      const { team } = cellValues.row;
      const score = team.categories.find((category: Category) => category.type === 'CompanyCategory')?.health;
      const color = computeScoreLevel(score).color;
      return <Typography color={color}>{score || '-'}</Typography>;
    },
  };
  if (companyActive) {
    columns.push(companyScoreColumn);
  }

  const managerColumn: TeamGridColDef = {
    field: 'manager',
    headerName: 'Manager',
    width: 120,
    valueGetter: ({ value }) => value || '-',
  };
  columns.push(managerColumn);

  const membersColumn: TeamGridColDef = {
    field: 'members',
    headerName: 'Members',
    width: 120,
    sortComparator: (a, b) => a.length - b.length,
    renderCell: (cellValues: GridRenderCellParams<TeamRow>) => {
      const { team } = cellValues.row;
      return team.users.length ? (
        <AvatarGroup max={3}>
          {team.users.map(user => (
            <Tooltip key={user.id} title={`${user.firstName} ${user.lastName}`}>
              <Avatar alt={`${user.firstName} ${user.lastName}`} src={user.avatar}>
                {user.firstName[0] + user.lastName[0]}
              </Avatar>
            </Tooltip>
          ))}
        </AvatarGroup>
      ) : (
        '-'
      );
    },
  };
  columns.push(membersColumn);

  const clientsColumn: TeamGridColDef = {
    field: 'clients',
    headerName: 'Clients',
    width: 80,
    sortComparator: (a, b) => a.length - b.length,
    valueGetter: ({ value }) => value || '-',
  };
  if (clientsActive) {
    columns.push(clientsColumn);
  }

  const projectsColumn: TeamGridColDef = {
    field: 'projects',
    headerName: 'Projects',
    width: 80,
    // sortComparator: (a, b) => a.length - b.length,

    /* renderCell: cellValues => 0 {
      const { team } = cellValues.row;
      return team.projects.length ? team.projects.length : '-';
    }*/
  };
  if (projectsActive) {
    columns.push(projectsColumn);
  }

  const intelligenceColumn: TeamGridColDef = {
    field: 'intelligence',
    headerName: 'Intelligence',
    width: 120,
    // align: 'center',
    renderCell: cellValues => {
      const { team } = cellValues.row;
      return team.intelligence || '-';
    },
  };
  columns.push(intelligenceColumn);

  const actionsColumn: TeamGridColDef = {
    field: 'actions',
    headerName: ' ',
    width: 64,
    sortable: false,
    renderCell: cellValues => {
      const { team } = cellValues.row;
      const ActionsComponent = actionsComponent;
      return <ActionsComponent team={team} />;
    },
  };
  columns.push(actionsColumn);

  return columns;
};

export default TeamDataGrid;
