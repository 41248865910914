import { Outlet } from 'react-router';
import Stack from '@mui/material/Stack';
import TertiaryNavigationTabs, { TertiaryNavigationTab, useTertiaryNavigationTabs } from '../Navigation/TertiaryNavigationTabs';
import { findModuleScoreDimensionSettings } from '../Module/ModuleDimensionSettings';
import AppSurface from '../UI/AppSurface';
import { useCategory } from '../Category/useCategory';
import { Scorable } from '../Scores/Scorable';

const Aspects = ({ scorable }: { scorable: Scorable }) => {
  const category = scorable.categories[0]; // only one category will be present due to API filtering
  const { categoryPath } = useCategory(category.type);
  const modules = category.modules;

  const tabs: TertiaryNavigationTab[] = modules.map(module => ({
    key: module.id,
    icon: findModuleScoreDimensionSettings(module.type).icon,
    label: module.name,
    value: module,
    path: `modules/${module.id}/aspects`,
  }));

  const tertiaryNavigationTabProps = useTertiaryNavigationTabs(tabs, categoryPath);
  const { selectedTab } = tertiaryNavigationTabProps;

  if (!scorable) {
    return null;
  }

  const selectedModule = selectedTab.value;

  const module = modules.find(module => module.id === selectedModule.id);
  //  const aspects = selectedModule?.aspects || []; // loading

  return (
    <Stack spacing={4}>
      <TertiaryNavigationTabs {...tertiaryNavigationTabProps} />
      <AppSurface>
        <Outlet context={{ scorable, module: module }} />
      </AppSurface>
    </Stack>
  );
};

export default Aspects;
