import React from 'react';
import { RouterProvider, createBrowserRouter, createRoutesFromElements, Outlet } from 'react-router';
import { ApolloProvider } from '@apollo/client';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';

import { useApolloClient } from './util/apolloClient';
import Partners from './util/Partners';
import { ErrorBoundary } from 'react-error-boundary';

import { AuthenticationProvider } from './User/AuthenticationContext';
import { UserProvider } from './User/UserContext';
import { TimeframeProvider } from './User/TimeframeContext';

import { WorkspaceErrorBoundary } from './UI/ErrorPage';
import { LayoutProvider } from './UI/LayoutContext';
import AppThemeProvider from './Theme/AppThemeProvider';
import AppLayout from './UI/AppLayout';
import AppStyle from './UI/AppStyle';
import { GlobalMessageProvider } from './UI/GlobalMessage';
import Main from './UI/Main';
import ModalContainer from './UI/ModalContainer';

import ScrollToTop from './UI/ScrollToTop';
import { routes } from './Routes/routes';
import VersionCheck from './UI/VersionCheck';
import { WorkspaceSelectionProvider } from './Workspace/useWorkspaceSelection';
import PageTitle from './UI/PageTitle';

const RootLayout = () => {
  return (
    <AuthenticationProvider>
      <AppApolloProvider>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <LayoutProvider>
            <PageTitle />
            <ScrollToTop />
            <UserProvider>
              <Partners />
              <WorkspaceSelectionProvider>
                <AppThemeProvider>
                  <TimeframeProvider>
                    <AppLayout>
                      <AppStyle>
                        <ModalContainer />
                        <VersionCheck />
                        <GlobalMessageProvider>
                          <ErrorBoundary FallbackComponent={WorkspaceErrorBoundary}
                                         onError={error => console.error('INNER ERROR BOUNDARY', error)}>
                            <Main>
                              <Outlet />
                            </Main>
                          </ErrorBoundary>
                        </GlobalMessageProvider>
                        {/*</ErrorBoundary>*/}
                      </AppStyle>
                    </AppLayout>
                  </TimeframeProvider>
                </AppThemeProvider>
              </WorkspaceSelectionProvider>
            </UserProvider>
          </LayoutProvider>
        </LocalizationProvider>
      </AppApolloProvider>
    </AuthenticationProvider>
  );
};

const router = createBrowserRouter(createRoutesFromElements(routes(<RootLayout />)), {
  future: {
    v7_relativeSplatPath: true,
    v7_fetcherPersist: true,
    v7_normalizeFormMethod: true,
    v7_partialHydration: true,
    v7_skipActionErrorRevalidation: true,
  },
});

const App = () => {
  return <RouterProvider router={router} />;
};

const AppApolloProvider = ({ children }: { children: React.ReactNode }) => {
  const client = useApolloClient();
  if (!client) {
    return null;
  }
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default App;
