import { useNavigate } from 'react-router';
import { useEffect } from 'react';
import useWorkspace from '../Workspace/useWorkspace';

const useSystemAdminRoute = () => {
  const navigate = useNavigate();
  const { isSystemAdmin } = useWorkspace();

  useEffect(() => {
    if (!isSystemAdmin) {
      navigate('/not-authorized');
    }
  }, [isSystemAdmin, navigate]);
};

export default useSystemAdminRoute;
