import React from 'react';
import SyncAltRoundedIcon from '@mui/icons-material/SyncAltRounded';
import { Avatar, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import AppSurface from '../UI/AppSurface';
import { SectionHeader, SectionHeaderIcon, SectionHeaderTitle } from '../UI/Section';
import SlackIntegrationControls from '../Account/SlackIntegrationControls';
import { useWorkspace } from '../Workspace/useWorkspace';

const Integrations = () => {
  const { workspace } = useWorkspace();

  return (
    <AppSurface>
      <SectionHeader>
        <SectionHeaderIcon>
          <SyncAltRoundedIcon />
        </SectionHeaderIcon>
        <SectionHeaderTitle>Integrations</SectionHeaderTitle>
      </SectionHeader>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Status</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key={'slack'}>
            <TableCell className="name-cell">
              <Avatar src="//logo.clearbit.com/slack.com" className="avatar" style={{ float: 'left' }} />
              Slack
            </TableCell>
            <TableCell>{workspace.hasSlack ? `Added ${workspace.slackTeamName} Hyve app to Slack workspace` : 'Not Added'}</TableCell>
            <TableCell align="right">
              <SlackIntegrationControls type="app" />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </AppSurface>
  );
};

export default Integrations;
