import { IconType } from '../UI/Icon';
import { Aspect } from '../Aspect/Aspect';
import { Category } from '../Category/Category';
import { MemberScorerInterface } from '../Membership/MemberScores';
import { ScoreDimensionBase } from '../Scores/ScoreDimensionBase';
import ProjectCollector from '../Projects/ProjectCollector';
import { z } from 'zod';
import { ClientCollector } from '../Client/ClientCollector';
import Question from './Question';

export const MODULES = [
  'ClientsProjectsModule',
  'ClientsProductsAndServicesModule',
  'ClientsRelationshipModule',
  'EngagementModule',
  'DiversityEquityAndInclusionModule',
  'HealthAndWellnessModule',
  'BurnoutModule',
  'CompanyProjectsModule',
  'CoreValuesModule',
  'SocialResponsibilityModule',
  'SeniorLeadershipModule',
] as const;

export const moduleTypeSchema = z.enum(MODULES);

export type ModuleType = z.infer<typeof moduleTypeSchema>;

export interface ModuleInterface extends ScoreDimensionBase, MemberScorerInterface, ClientCollector, ProjectCollector {
  __typename: ModuleType;
  type: ModuleType;
  key: string;
  active: boolean;
  icon: IconType;
  summary: string;
  description: string;
  aspects: Aspect[];
  category?: Category;
  questions: [Question];
}
