import React from 'react';
import { useNavigate } from 'react-router';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import useWorkspaceSelection from '../Workspace/useWorkspaceSelection';
import useAuthenticatedUser from '../User/useAuthenticatedUser';

const WorkspaceSelector = () => {
  const navigate = useNavigate();
  const { workspaceId, setWorkspaceId } = useWorkspaceSelection();
  const { user } = useAuthenticatedUser();
  const workspaces = user.workspaces;

  const handleChange = (event: SelectChangeEvent) => {
    const workspaceId = event.target.value;
    const workspace = workspaces.find(workspace => workspaceId === workspace.id);
    if (!workspace) {
      throw new Error();
    }
    setWorkspaceId(workspace.id);
    // dispatch({ type: CHANGE_WORKSPACE, workspace });
    navigate(`/workspace/${workspaceId}`);
  };

  return (
    <FormControl variant='outlined' fullWidth>
      <InputLabel id='demo-simple-select-outlined-label'>Workspace</InputLabel>
      <Select variant='outlined' label='Workspace' fullWidth value={workspaceId || undefined} onChange={handleChange}>
        {workspaces.map(workspace => {
          // console.log(workspace)
          return (
            <MenuItem key={workspace.id} value={workspace.id}>
              {workspace.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default WorkspaceSelector;
