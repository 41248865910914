import React from 'react';
import Icon from '@mui/material/Icon';
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import CorporateFareRoundedIcon from '@mui/icons-material/CorporateFareRounded';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import HiveRoundedIcon from '@mui/icons-material/HiveRounded';
import SupervisedUserCircleRoundedIcon from '@mui/icons-material/SupervisedUserCircleRounded';

import { useWorkspace } from '../Workspace/useWorkspace';
import { useMatches } from 'react-router';
import WaterDropRoundedIcon from '../Icons/WaterDropRoundedIcon';
import useActiveFeatures from '../Module/useActiveFeatures';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';

export type NavigationItem = {
  key: NavigationKey;
  name: string;
  icon: React.ReactElement;
  ref?: React.RefObject<HTMLDivElement | null>;
  onClick?: () => void;
};

export type PrimaryNavigationArea = NavigationItem & {
  label?: string;
  mediumLabel?: string;
  path?: string;
  badge?: boolean;
  active?: boolean;
};

export type PrimaryNavigationAreas = { [key: string]: PrimaryNavigationArea };

export type NavigationKey =
  | 'workspace'
  | 'intelligence'
  | 'clients'
  | 'projects'
  | 'employee-experience'
  | 'company'
  | 'teams'
  | 'ai'
  | 'help'
  | 'invite'
  | 'account';

type MatchHandle = { area: string | undefined };

const useActiveAreaName = () => {
  const matches = useMatches();
  const areaMatch = matches.find(match => {
    const handle = match.handle as MatchHandle;
    return handle?.area;
  });

  const handle = areaMatch?.handle as MatchHandle;
  return handle.area || null;
};

export const usePrimaryNavigation = () => {
  const { workspace } = useWorkspace();

  const activeAreaName = useActiveAreaName();
  const { aiActive, clientsActive, employeeExperienceActive, companyActive, projectsActive, teamsActive } = useActiveFeatures();

  if (!workspace) {
    throw new Error('Workspace undefined');
  }

  const areas: PrimaryNavigationAreas = {};

  areas.workspace = {
    key: 'workspace',
    name: 'Workspace',
    label: 'Dashboard',
    path: `/workspace/${workspace.id}`,
    icon: <DashboardRoundedIcon className='navigation-icon' />,
  };

  areas.intelligence = {
    key: 'intelligence',
    name: 'Intelligence',
    label: 'Intelligence',
    badge: workspace.needsMyIntelligence,
    path: `/workspace/${workspace.id}/intelligence`,
    icon: <WaterDropRoundedIcon className='navigation-icon' />,
  };

  if (clientsActive) {
    areas.clients = {
      key: 'clients',
      name: 'Clients',
      label: 'Clients',
      path: `/workspace/${workspace.id}/clients`,
      icon: <CorporateFareRoundedIcon className='navigation-icon' />,
    };
  }

  if (projectsActive) {
    areas.projects = {
      key: 'projects',
      name: 'Projects',
      label: 'Projects',
      path: `/workspace/${workspace.id}/projects`,
      icon: <HiveRoundedIcon className='navigation-icon' />,
    };
  }

  if (employeeExperienceActive) {
    areas['employee-experience'] = {
      key: 'employee-experience',
      name: 'Employee Experience',
      label: 'Employee Experience',
      path: `/workspace/${workspace.id}/employee-experience`,
      icon: <AutoAwesomeRoundedIcon className='navigation-icon' />,
    };
  }

  if (companyActive) {
    areas.company = {
      key: 'company',
      name: 'Company',
      label: 'Company',
      path: `/workspace/${workspace.id}/company`,
      icon: <ApartmentRoundedIcon className='navigation-icon' />,
    };
  }

  if (teamsActive) {
    areas.teams = {
      key: 'teams',
      name: 'Teams',
      label: 'Teams',
      path: `/workspace/${workspace.id}/teams`,
      icon: <SupervisedUserCircleRoundedIcon className='navigation-icon' />,
    };
  }

  if (aiActive) {
    areas.ai = {
      key: 'ai',
      name: 'Hyve AI',
      label: 'Hyve AI',
      mediumLabel: 'Hyve AI',
      icon: <Icon className='navigation-icon'>bolt</Icon>,
    };
  }

  areas.help = {
    key: 'help',
    name: 'Help',
    label: 'Help',
    mediumLabel: 'Help',
    icon: <Icon className='navigation-icon'>help</Icon>,
  };

  areas.account = {
    key: 'account',
    name: 'Account',
    label: 'Account',
    mediumLabel: 'Account',
    path: `/workspace/${workspace.id}/account/profile`,
    icon: <Icon className='navigation-icon'>account_circle</Icon>,
  };

  const activeArea = Object.values(areas).find(area => area.name === activeAreaName);

  return { areas, activeArea, activeAreaName };
};
