import React from 'react';
import { Stack } from '@mui/material';

const SurveyMessage = ({ children }: { children: React.ReactNode }) => {
  return (
    <Stack spacing={2} sx={{ zIndex: 1 }}>
      {children}
    </Stack>
  );
};

export default SurveyMessage;
