import { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { useNavigate } from 'react-router';

import { useGlobalMessage } from '../UI/GlobalMessage';
import CollectIntelligenceDialog, { CollectIntelligenceValues } from '../Intelligence/CollectIntelligenceDialog';
import { useWorkspace } from '../Workspace/useWorkspace';
import { useIntelligence } from './IntelligenceContext';
import { Scorable } from '../Scores/Scorable';
import { ScoreDimension } from '../Scores/ScoreDimension';

export const useContributeIntelligence = (scorable?: Scorable, scoreDimension?: ScoreDimension) => {
  const navigate = useNavigate();
  const { workspace } = useWorkspace();
  const { setIntelligenceAction } = useIntelligence();
  const { setError } = useGlobalMessage();
  const [contributeIntelligenceDisabled, setContributeIntelligenceDisabled] = useState(false);

  const [contributeIntelligenceMutation] = useMutation(CONTRIBUTE_INTELLIGENCE_MUTATION, {
    variables: {
      scorable: { type: scorable?.__typename, id: scorable?.id },
      aggregateType: scorable === scoreDimension ? undefined : scoreDimension?.__typename,
    },
  });

  const contributeIntelligence = async () => {
    setContributeIntelligenceDisabled(true);
    const {
      data: {
        contributeIntelligence: { success },
      },
    } = await contributeIntelligenceMutation();

    if (success) {
      setContributeIntelligenceDisabled(false);
      navigate(`/workspace/${workspace.id}/intelligence`);
      setIntelligenceAction({ action: 'contribute' });
    } else {
      setError('Error contributing intelligence');
      setContributeIntelligenceDisabled(false);
    }
  };

  return { contributeIntelligence, contributeIntelligenceDisabled };
};

export const CONTRIBUTE_INTELLIGENCE_MUTATION = gql`
  mutation contributeIntelligence($scorable: ScorableInput!, $aggregateType: AggregateType) {
    contributeIntelligence(scorable: $scorable, aggregateType: $aggregateType) {
      success
    }
  }
`;

export const useCollectIntelligence = (scorable: Scorable, scoreDimension?: ScoreDimension) => {
  const { setIntelligenceAction } = useIntelligence();
  const { setSuccess, setError } = useGlobalMessage();
  const [collectIntelligenceDisabled, setCollectIntelligenceDisabled] = useState(false);
  const [open, setOpen] = useState(false);

  const [collectIntelligenceMutation, { error }] = useMutation(COLLECT_INTELLIGENCE_MUTATION);

  const collectIntelligence = () => {
    setOpen(true);
  };

  const handleSubmit = async (values: CollectIntelligenceValues) => {
    const { message } = values;

    setCollectIntelligenceDisabled(true);

    // TODO: migrate to useFormDialog handleResponse
    const response = await collectIntelligenceMutation({
      variables: {
        scorable: { type: scorable.__typename, id: scorable.id },
        aggregateType: scorable === scoreDimension ? undefined : scoreDimension?.__typename,
        message: message,
      },
    });

    const {
      data: {
        collectIntelligence: { success },
      },
    } = response;

    if (success) {
      setIntelligenceAction({ action: 'collect' });
      setSuccess('Collecting intelligence');
    } else {
      setError('Error collecting intelligence');
    }

    setCollectIntelligenceDisabled(false);
    setOpen(false);
    return success;
  };

  const collectIntelligenceDialog = (
    <CollectIntelligenceDialog open={open} onClose={() => setOpen(false)} scorable={scorable} onSubmit={handleSubmit} error={error} />
  );

  return { collectIntelligence, collectIntelligenceDisabled, collectIntelligenceDialog };
};

export const COLLECT_INTELLIGENCE_MUTATION = gql`
  mutation collectIntelligence($scorable: ScorableInput!, $aggregateType: AggregateType, $message: String) {
    collectIntelligence(scorable: $scorable, aggregateType: $aggregateType, message: $message) {
      success
    }
  }
`;
