import React from 'react';
import PageNotice from '../UI/PageNotice';
import { useWorkspace } from '../Workspace/useWorkspace';
import LinkRoundedIcon from '@mui/icons-material/LinkRounded';
import { useAuthorizeSlack } from './useSlackIntegration';

const SlackIntegrationNotice = () => {
  const { workspace, isAdmin } = useWorkspace();
  const { authorizeAppScopesURL } = useAuthorizeSlack();
  const [shouldRedirect, setShouldRedirect] = React.useState(false);

  React.useEffect(() => {
    if (shouldRedirect) {
      window.location.href = authorizeAppScopesURL;
    }
  }, [shouldRedirect, authorizeAppScopesURL]);

  if (!workspace) {
    return null;
  }

  const actions = [
    {
      id: 'link-to-slack',
      icon: LinkRoundedIcon,
      label: 'Add to Slack',
      onSelect: () => {
        setShouldRedirect(true);
      },
    },
  ];

  const applicable = isAdmin && !workspace.hasSlack;

  return (
    <PageNotice
      noticeKey={`slack-integration-Workspace:${workspace.id}`}
      applicable={applicable}
      level="high"
      message="Add Hyve to your Slack workspace so members can receive notifications and answer questions directly in Slack."
      actions={actions}
    />
  );
};

export default SlackIntegrationNotice;
