import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Button, Icon } from '@mui/material';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import AppSurface from '../UI/AppSurface';
import { SectionHeader, SectionHeaderIcon, SectionHeaderTitle, SectionHeaderActions } from '../UI/Section';
import { UserInterface } from '../User/User';
import MySettingsDialog from './MySettingsDialog';
import SlackIntegrationControls from './SlackIntegrationControls';
import useWorkspace from '../Workspace/useWorkspace';

interface MySettingsProps {
  user: UserInterface;
  refetch: () => Promise<any>;
}

const MySettings = ({ user, refetch }: MySettingsProps) => {
  const [open, setOpen] = useState(false);
  const { workspace } = useWorkspace();

  return (
    <StyledAppSurface>
      <SectionHeader>
        <SectionHeaderIcon>
          <Icon className="icon">person</Icon>
        </SectionHeaderIcon>
        <SectionHeaderTitle>My Settings</SectionHeaderTitle>
        <SectionHeaderActions>
          <Button name="my-settings" onClick={() => setOpen(true)}>
            Edit
          </Button>
          <MySettingsDialog user={user} open={open} onClose={() => setOpen(false)} refetch={refetch} />
        </SectionHeaderActions>
      </SectionHeader>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Setting</TableCell>
            <TableCell>Status</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow>
            <TableCell>Workplace</TableCell>
            <TableCell>{user.officeType}</TableCell>
            <TableCell></TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Roles</TableCell>
            <TableCell>Coming soon</TableCell>
            <TableCell></TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Time Zone</TableCell>
            <TableCell>{user.timeZone}</TableCell>
            <TableCell></TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Theme</TableCell>
            <TableCell>{user.theme}</TableCell>
            <TableCell></TableCell>
          </TableRow>

          {workspace.hasSlack && (
            <>
              <TableRow>
                <TableCell>Slack Direct Message Notifications</TableCell>
                <TableCell>{user.slackMessagingDisabled ? 'Disabled' : 'Enabled'}</TableCell>

                <TableCell align="right">
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Slack Private Channel Access</TableCell>
                <TableCell>{user.hasSlackUserAccess ? 'Authorized' : 'Not Authorized'}</TableCell>

                <TableCell align="right">
                  <SlackIntegrationControls type="user" />
                </TableCell>
              </TableRow>
            </>
          )}
        </TableBody>
      </Table>
    </StyledAppSurface>
  );
};

const StyledAppSurface = styled(AppSurface)`
  .MuiTableCell-root:first-of-type {
    width: 33%;
  }
`;

export default MySettings;
